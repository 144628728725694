<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    custom-class="tipDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('icon_i')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="cancel"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <span> 退出页面内容不会保存，请确认是否退出页面 </span>
      </div>
      <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small"
          >确认退出</fm-button
        >
        <fm-button @click="cancel" type="info" plain size="small"
          >取 消</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'base',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    confirm: Function,
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss">
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-modal {
  opacity: 0.4;
}
.el-dialog {
  margin: 0 !important;
  border-radius: 16px;
  background: #ffffff;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    padding: 20px 24px 24px 24px;
    border-radius: 16px;
    color: #2c2c2c;
  }
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .fm-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .d-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
.baseDialog {
  .d-header {
    .left {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .d-content {
    margin: 24px 0;
    span {
      line-height: 22px;
    }
  }
}
.tipDialog {
  .d-header {
    .left {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .d-content {
    margin: 34px 0 24px 0;
    text-align: center;
    span {
      font-size: 16px;
      line-height: 24px;
    }
    .txt {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px !important;
      }
    }
  }
}
.titleDialog {
  .d-header {
    .left {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .d-content {
    margin: 32px 0 24px 0;
    .title {
      font-size: 16px; //设计图有问题18 ——> 16
      line-height: 24px;
      text-align: center;
    }
    .txt {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      span {
        line-height: 22px;
      }
    }
  }
}
.PromoteDialog {
  .d-content {
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    img {
      width: 88px;
      height: 88px;
      margin-bottom: 21px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .d-footer {
    justify-content: center;
    .fm-button.fm-button-primary {
      height: 36px;
      font-size: 14px;
      width: 120px;
      height: 36px;
      font-weight: 500;
      margin-left: 0;
    }
  }
}
.collectDialog {
  .d-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    img {
      width: 96px;
      margin-top: 24px;
    }
    span {
      font-size: 16px;
      color: #2c2c2c;
      line-height: 24px;
      margin-top: 15px;
    }
  }
  .d-footer {
    margin-top: 32px;
    justify-content: center;
    margin-bottom: 8px;
    .fm-button {
      width: 120px;
      height: 36px;
    }
  }
}
// 验证码输入框
.verifcode-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    margin: 0 10px;
    display: flex;
    align-items: center;
    .line {
      width: 10px;
      height: 1px;
      background: #2c2c2c;
      margin: 0 4px 0 24px;
    }
  }
  .el-input__inner {
    width: 40px;
  }
}
.fileDirDialog {
  .el-dialog__body {
    padding: 0;
    .d-header {
      margin: 20px 0 0 24px;
    }
  }
  .file-directory {
    padding-left: 24px;
    width: 480px;
    height: 40px;
    background: #f9f9f9;
    color: #8a8b92;
    margin-top: 16px;
  }
  .fileList {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 316px;
    position: relative;
    box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    padding: 16px 12px 32px 12px;
    .item {
      width: 100%;
      padding: 9px 0;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      align-items: center;
      &:hover {
        background: #f6f6f6;
        color: #2c2c2c;
      }
      img {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        margin-left: 12px;
      }
      span {
        font-size: 13px;
        line-height: 18px;
        max-width: 392px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      .img {
        width: 72px;
        height: 72px;
        margin-bottom: 8px;
      }
      .span0 {
        color: #bdbdbe;
        width: 100%;
        display: flex;
        .span1 {
          max-width: 316px;
          @include multiline(1);
          margin: 0 8px;
        }
      }
    }
  }
  .d-footer {
    margin: 16px 24px 24px 0;
    .fm-button {
      .el-loading-mask {
        .el-loading-spinner .circular {
          width: 30px;
          height: 30px;
        }
        .el-loading-spinner {
          margin-top: -15px;
        }
      }
    }
  }
}
//补全信息弹框
.supplementInfo {
  .d-content {
    margin-top: 32px;
    .title {
      height: 24px;
      font-size: 16px;
      line-height: 24px;
      color: #2c2c2c;
    }
    .tips {
      margin-top: 12px;
      line-height: 22px;
      color: #2c2c2c;
    }
    .dc1 {
      margin-bottom: 12px;
      .el-input {
        width: 432px;
        height: 39px;
        line-height: 39px;
        .el-input__inner {
          padding-left: 54px;
        }
        .el-input-group__prepend {
          border: none;
          box-shadow: none;
          background: transparent;
          position: absolute;
          padding: 0 16px 0 12px;
          color: #2c2c2c;
        }
      }
    }
    .dc2 {
      margin-bottom: 24px;
      .el-input {
        width: 432px;
        height: 39px;
        line-height: 39px;
        .el-input__inner {
          padding-right: 0;
          width: 432px;
        }
        .el-input-group__append {
          border: none;
          box-shadow: none;
          background: transparent;
          position: absolute;
          right: 12px;
          position: absolute;
          width: 70px;
          padding: 0;
          top: 0;
          cursor: pointer;
          color: #f67942;
        }
      }
      .svg-icon {
        margin-right: 12px;
      }
    }
    .svg-icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
