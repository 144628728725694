<!--
 * @FilePath: /daren-html-source/src/views/Help/contactCust.vue
 * @Description: 联系客服组件
-->
<template>
  <div class="contactCust">
    <div class="pub-tit0" v-if="notice.length > 0">{{ $t('help.contactCust.LatestAnnouncements') }}</div>
    <div class="qTop" v-if="notice.length > 0">
      <div class="questionBox">
        <div
          class="qItem"
          @click="gohelpDetail(item)"
          v-for="item in notice.slice(
            0,
            Math.ceil(notice.length / 2)
          )"
          :key="item"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
      <div class="questionBox" v-if="notice.length > 1">
        <div
          class="qItem"
          v-for="item in notice.slice(
            Math.ceil(notice.length / 2),
            notice.length
          )"
          :key="item"
          @click="gohelpDetail(item)"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
    </div>
    <div :class="notice.length > 0 ?'fourMargin':''" class="pub-tit0" v-if="login.length > 0 || income.length > 0">{{ $t('help.contactCust.FAQ') }}</div>
    <div class="qTop">
      <div class="questionBox" v-if="login.length > 0">
        <span class="titleOne">{{ $t('help.contactCust.LoginRegister') }}</span>
        <div
          class="qItem"
          @click="gohelpDetail(item)"
          v-for="item in login"
          :key="item"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
      <div class="questionBox" v-if="income.length >0">
        <span class="titleOne">{{ $t('help.contactCust.WithdrawEarnings') }}</span>
        <div
          class="qItem"
          v-for="item in income"
          :key="item"
          @click="gohelpDetail(item)"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
    </div>
    <div class="pub-tit0" :class="notice.length == 0 && login.length == 0 && income.length == 0?'zeroMargin':'fourMargin'" style="margin-bottom:20px">
      {{ $t('help.contactCust.AdditionalHelp') }}
    </div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id">
        <img :src="$utils.getPng('icon_help' + item.id)" alt="" />
        <div class="info">
          <div class="tit">{{ item.name }}</div>
          <div class="btn pointer" @click="toLink(item)">
            {{ item.name1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionsV2 } from '@/utils/apiList/user'
export default {
  data() {
    return {
      list: [
        {
          id: 3,
          name: this.$t('help.contactCust.SubmitATicket'),
          name1: this.$t('help.contactCust.SubmitNow') + ' >',
          path: 'submitQuestions',
        },
        {
          id: 1,
          name: this.$t('help.contactCust.FeedbackHistory'),
          name1: this.$t('help.contactCust.ViewHistory') + ' >',
          path: 'help',
        },
        // {
        //   id: 2,
        //   name: this.$t('help.contactCust.TextOne'),
        //   name1: this.$t('help.contactCust.TextTwo') + ' >',
        //   path: 'help',
        // },
      ],
      questionList: [],
      notice:[],
      login:[],
      income:[],
    }
  },
  //提前加载联系客服数据,防止页面抖动
  props: ['mubieData'],
  watch: {
    mubieData(newVal) {
      this.notice = newVal?.notice;
      this.login = newVal?.login;
      this.income = newVal?.income;
    }
  },
  //如果父组件加载有问题,或者确实notice长度为0,以防万一,当前页面请求一次
  mounted() {
    if(this.notice.length == 0){
      this.getQuestion()
    }
  },
  methods: {
    toLink(item) {
      if (item.id == 0) {
        window.open(`${this.$glb.fmpUrl}/submitQuestions`, '_blank')
      } else if (item.id == 1) {
        // window.open(`${this.$glb.fmpUrl}/help?id=1`, '_blank')
        this.$emit('goNote',2)
      } else if (item.id == 2) {
        window.open('http://q.url.cn/CDKEvl?_type=wpa&qidian=true')
      } else if(item.id == 3){
        window.open(`/submitWork`, '_blank')
      }
    },
    getQuestion() {
      getQuestionsV2().then(res => {
        if (res.status == 1) {
          this.notice = res.data.list.notice
          this.login = res.data.list.login
          this.income = res.data.list.income
        }
      })
    },
    gohelpDetail(item) {
      this.$emit('goNote',1,item.id)
      // window.open(`${this.$glb.fmpUrl}/askdetail/${item.id}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.contactCust {
  .qTop {
    display: flex;
    justify-content: space-between;
    .questionBox {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 454px;
      background: #f9f9f9;
      border-radius: 16px;
      padding: 4px 16px;
      .qItem {
        width: 422px;
        height: 54px;
        box-shadow: inset 0px -1px 0px 0px #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #2c2c2c;
        cursor: pointer;
        .tit {
          @include multiline(1);
        }

        .btn {
          height: 54px;
          display: flex;
          margin-left: 32px;
          align-items: center;
          flex-shrink: 0;
          .svgBtn {
            margin-left: 8px;
          }
        }
      }
      :hover {
        color: #7E72F6;
      }
      :last-child {
        box-shadow: inset 0px 0px 0px 0px #e5e5e5;
      }
    }
    .none{
      display: flex;
      flex-direction: column;
      width: 454px;
      height: 300px;
      border-radius: 16px;
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    .item {
      display: flex;
      width: 200px;
      margin-right: 80px;
      img {
        width: 52px;
        height: 52px;
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        justify-content: space-between;
        .tit {
          height: 22px;
          font-weight: 500;
          font-size: 14px;
          color: #2c2c2c;
          line-height: 22px;
          white-space: nowrap;
        }
        .btn {
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          color: #8a8b92;
          line-height: 22px;
          white-space: nowrap;
        }
      }
    }
  }
  .fourMargin{
    margin-top: 40px;
  }
  .zeroMargin{
    margin-top: 0 !important;
  }
  .titleOne{
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #2C2C2C;
    line-height: 22px;
    width: 100%;
    &:hover{
      color: #2C2C2C !important;
    }
  }
}
</style>
