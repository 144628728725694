/*
 * @FilePath: /daren-html-source/src/store/index.js
 * @Description: vuex 状态管理
 */
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
// import utils from '../utils/index'
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = new Vuex.Store({
  state: {
    name: "feiMoo",
    userInfo: {},
    userMsgNub:0,
    toTop: function () { },
    getUserInfo: function () { },
    getNewCookie:function(){},
    getfilescallback:function(){},
  },
  getters: {},
  mutations: {
    setAttr(state, data) {
      state[data.name] = data.val;
    },
    toTop(state, opt) {
      state?.toTop(opt);
    },
    getUserInfoVuex(state, opt) {
      state?.getUserInfo(opt);
    },
    getNewCookieVuex(state, opt) {
      state?.getNewCookie(opt);
    },
    // 刷新文件列表
    getfiles(state,opt) {
      state?.getfilescallback(opt);
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});

export default store;
