//不要格式化
export default {
  home: {
    Login: 'Log in', // 登录
    Sign: 'Sign up', // 立即注册
    Slogan: 'Share every moment, Reward every moment', //分享每一刻 收获每一刻
    ImmedSign: 'Sign in', // 立即注册
    Enter: 'Enter CloudMaster', // 进入CloudMaster
    Signup: 'Register a FEEMOO account', // 注册飞猫盘账号
    Simple: 'Super easy registration', // 超简单极速注册
    Master: 'liberal CloudMaster', // 开通飞猫达人
    Exclusive: 'Exclusive logo/rights', //达人专属标识/权益
    ShareFile: 'Share files for revenue', // 分享文件赚收益
    Others: 'Others download/save and get paid', // 他人下载/转存即获得收益
    Advantages: 'Platform Advantages', // 平台优势
    Multiple: 'Multiple modes of flexible play', //多种模式灵活玩法多样
    CardTitle1: 'FEEMOO Rebate', // FEEMOO返利
    CardText1: 'FEEMOO is a sharing network disk, after becoming a master upload files, generate sharing links for others to download.', // 
    CardText1_2prefix: '', //可选择
    CardText1_2Blod: 'Download rebate model', //下载量返利模式
    CardText1_2suffix: ' can be selected, the size of the file according to the download rebate, the classification of 10,000 downloads up to 1888 yuan, every Wednesday and Thursday double revenue. ', //，区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    CardText1_3prefix: 'Or choose the ', //或选择
    CardText1_3Blod: 'member rebate model', //会员返利模式
    CardText1_3suffix: ', Do not charge according to the number of downloads, only according to the proportion of download users to open members, files do not expire, the page is more concise.', //，不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁。
    CardTitle2: 'Promo code rebate', // 优惠码返利
    CardText2: 'Use your exclusive invitation code to invite users to open FEEMOO premium, you can get a rebate.', // 使用专属您的邀请码邀请用户开通飞猫盘会员，您可获得返利收益
    CardTitle3: 'Downline Rebate', // 下线返利
    CardText3: `Friends apply to become CloudMaster after registering FEEMOO account through invitation link, you can get the share rebate of friends' revenue.`, // 好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的分成返利。
    Income: 'Real income', // 真实收益
    Random: `Random display of last week's partial income withdrawal record real withdrawal can be seen`, // 随机展示上周部分收益提现记录 真实提现看得到
    Feedback: 'Real user feedback', // 真实用户反馈
    Realization: 'Ten years of online traffic realization platform, real feedback', // 上线十年流量变现平台，真实反馈
    Enjoy: 'Enjoy the fun of sharing and easily earn\nyour share of realizing gains', // 享受分享的乐趣，轻松赚取属于你的变现收益。
  },
  nav: { //头部模块
    Login: 'Log in', //登录
    Sign: 'Sign up', //注册
    Account: 'Account', //个人账户
    SignOut: 'Sign Out', //退出
  },
  GradeColor: { //等级颜色
    Bronze:'Bronze',//青铜
    Silver:'Silver',//白银
    Gold:'Gold',//黄金
    Diamond:'Diamond',//钻石
    Master:'Master',//大师
    King:'King',//王者
  },
  IntrDaren: { //未登录展示
    FeeMooMaster: 'FEEMOO Master', //飞猫达人
    TextOne: 'Unlock a number of rebates after opening FEEMOO Master !', //开通成为飞猫达人后即可解锁多项推广返利功能！
    OpenVIP: 'Open Now', //立即开通
    PlatformPlay: 'Platform play', //平台玩法
    PlateRebate: 'FEEMOO plate rebate', //飞猫盘返利
    TextFive: 'Share files with other users to download/save for revenue', //分享文件给其他用户下载/转存获得收益
    PromoCodeRebate: 'Promo code rebate', //优惠码返利
    TextSix: 'Users get a rebate by opening a membership through a promo code', //用户通过优惠码开通会员获得返利收益
    DownlineRebate: 'Downline rebate', //下线返利
    TextSeven:
      'Invite friends to become a FEEMOO Master to get friends is share of the revenue', //邀请好友成为飞猫达人获得好友的收益分成
    ActivityRule: 'Activity rule', //活动规则
    TextTwo: 'The applicant should complete the information of the payee', //申请人需完善收款人信息
    TextThree: 'Platform rules need to be followed', //需遵守平台规则
    TextFour: 'User service agreement', //《飞猫盘用户服务协议》
  },
  FmCloud: { //飞猫盘模块
    DownloadRebateMode: 'Download rebate model', //下载量返利模式
    YouHave: 'You have', //您有
    FilesUsingMode: 'files using this mode', //个文件使用该模式
    MyGrade: 'My grade: ', //我的等级：
    TextOne:
      'Distinguish the size of the file according to the download rebate, distinguish the level of 10,000 downloads up to 1888 yuan, every Wednesday and Thursday double income,', //区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益，
    DetailedIntroduction: ' detailed introduction.', //详细介绍
    LargeFileIncome: 'Large file income', //大文件收益
    SmallFileIncome: 'Small file income', //小文件收益
    TextTwo: 'Today more than 100M file downloads (times)', //今日大于100M文件下载量（次）
    TextThree: 'Today less than 100M file downloads (times)', //今日小于100M文件下载量（次）
    WeekAward: 'FEEMOO of the Week award', //每周飞猫达人奖
    TextFour: 'Automatic redemption to balance at 8am the next day', //次日上午8点自动兑换到余额
    NotYetAwarded: 'Not yet awarded', //暂未获奖
    ReceiveReward: 'Receive a reward', //领取奖励
    Claimed: 'Claimed', //已领取
    MemberRebateModel: 'Member rebate model', //会员返利模式
    TextFive: 'Prizes are awarded on Wednesdays', //每周三评奖
    TextSix:
      'Do not charge according to the VIP of downloads, only according to the proportion of download users to open members, files do not expire, the page is more concise,', //不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁，
    RebateVIP: 'Rebate VIP', //返佣个数
    CommissionRebate: 'Commission rebate', //返佣金额
    VIPRebatesToday: 'VIP of rebates today', //今日返佣个数
    CommissionBackToday: 'Commission back today', //今日返佣个数
  },
  NavLeft: { //左侧菜单模块
    FEEMOO: 'Feemoo', //飞猫盘
    PromotionCode: 'Promotion code', //优惠码
    DownlineRebate: 'Downline rebate', //下线返利
    HelpCenter: 'Help center', //帮助中心
    BalanceWithdrawal: 'Balance', //余额提现
    AccountManagement: 'Settings', //账号管理
  },
  MemberRebate: { //优惠码模块
    RebateCode: 'Rebate code', //返利优惠码
    IncomeData: 'Income data', //收益数据
    //返利优惠码模块
    rebateDisCode: {
      YourCurrentLevel: 'Your current level：', //您当前等级：
      Levels: 'Levels', //等级介绍
      CouponCodeDiscount: 'Coupon code discount', //优惠码折扣
      RebateRatio: 'Rebate ratio', //返利比例
      MyPromoCode: 'My promo code：', //我的优惠码：
      MyCode:'My promo code',//我的优惠码
      Copy: 'Copy', //复制
      CopyCode: 'Copy promo code', //复制优惠码
      MemberRebateIncome: 'Member rebate income', //会员返利收益
    },
    //收益数据模块
    incomeData: {
      MemberRebateProgram: 'Member rebate program', //会员返利计划
      TextOne: 'Users can earn money by opening a membership through the promo code', //用户通过优惠码开通会员，您可获得收益
      Levels: 'Levels', //等级介绍
      MyGrade: 'My grade: ', //我的等级：
      MemberRebateIncome: 'Member rebate income', //会员返利收益
      OpenMembership:'Open membership',//开通会员人数
      UserNickname: 'User nickname', //用户昵称
      OpeningTime: 'Opening time', //开通时间
      RebateAmount: 'Rebate amount', //返利金额
    },
  },
  OfflineRebate: { //下线返利模块
    InviteRegisterLink:'Invite to register link',//邀请注册链接
    IncomeData: 'Income data', //收益数据
    yuan:'￥',//元
    InvitationLink:{ //邀请注册链接
      Text:'Gameplay description',//玩法说明
      TextOne:'Friends through the invitation link to register the FEEMOO account after application Become a FEEMOO Master, you can get high friend income Rebates.',//好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的高额返利。
      TextTwo:'• You need to register a FEEMOO account through your invitation link Gong, and apply to become a FEEMOO Master.',//需通过你的邀请链接注册飞猫盘账号成功，并且申请开通成为飞猫达人
      TextThree:'• When your friend becomes a master and gets a FEEMOO rebate, you can get a share of your friend is revenue.',//好友成为达人后获得飞猫盘返利收益，你就能获得好友的收益分成。
      TextFour:'• The more friends you invite to become masters, the more revenue points you get Okay, no caps on top.',//邀请越多好友成为达人，获得更多收益分成，上不封顶
      InviteNow:'Invite now',//立即邀请
      CopyInvitationLink:'Copy invitation link',//复制邀请链接
      SavePicture:'Save picture',//保存图片
      DownLineIncome:'Down-line income',//下线收益
      Save:'Save',//保存
      TodayEarnings:'Today is earnings',//今日收益
      Yesterday:'Yesterday',//昨日
      CurrentEarnings:'Current earnings',//本月收益
      SeeMore:'See more',//查看更多
      InvitationRecord:'Invitation record',//邀请记录
      UserNickname:'User nickname',//用户昵称
      TextFive:'Open CloudMaster',//是否开通飞猫达人
      InvitationTime:'Invitation time',//邀请注册时间
      TextSix:'Get a ¥99 downline rebate',//获得99元下线返利
      HaveOpened:'Have opened',//已开通
      HaveNotOpened:'Have not opened',//未开通
    },
    offIncomeData:{ //收益数据
      TodayEarnings:'Today is earnings',//今日收益
      YesterdayEarnings:'Yesterday is earnings',//昨日收益
      CurrentEarnings:'Current earnings',//本月收益
      TextOne:'Down line rebate income',//收益记录
      UserNickname:'User nickname',//用户昵称
      TextTwo:'Rebate amount (¥)',//返利金额（元）
      TextThree:'Why I have not received offline rebate revenue: ',//为什么我还没有获得下线返利收益：
      TextFour:'1, check the status of friends: please check the invitation record, confirm whether friends have become a FEEMOO master.',//1、检查好友状态：请查看邀请记录，确认好友是否已成为飞猫达人。
      TextFive:'2, wait for revenue settlement: If your friends are already FEEMOO, you need to wait for them to generate revenue and settle the next day before you can get a share of the rebate revenue.',//2、等待收益结算：若好友已是飞猫达人，需等待他们产生收益并在次日结算后，您才能获得返利收益分成。
    },
  },
  help: { //帮助中心模块
    FEEMOO:'Feemoo',//飞猫盘
    CouponCode:'Coupon Code',//优惠码
    DownlineRebate:'Downline Rebate',//下线返利
    ComplaintBox:'Complaint box',//意见箱
    ContactCustomer:'Contact customer',//联系客服
    fmp:{ //飞猫盘
      EarningsProfile:'Earnings profile',//收益简介
      TextOne:'Share files for other users to download/save you can earn revenue, daily income is automatically settled the next morning. ',//分享文件给其他用户下载/转存你就能获得收益，每日所得收益次日凌晨自动结算。
      TextTwo:'Download rebate model：',//下载量返利模式：
      TextThree:'Distinguish the size of the file according to the',//区分大小文件按
      TextFour:'download rebate',//下载量返利
      TextFive:'，distinguish the level of 10,000 downloads up to',//，区分等级万次下载最高
      TextSix:' ¥1,888',//1888元
      TextSeven:'，every Wednesday and Thursday',//，每周三、周四
      TextEight:' double',//双倍
      TextNine:' revenue.',//收益。
      TextTen:'Member rebate model：',//会员返利模式：
      TextEleven:'do not charge by download, only root According to the download user opens the membership to be',//不按下载次数计费，只根据下载用户开通会员进行按
      TextTwelve:'divided proportionally',//比例分成
      TextThirteen:'，the article The piece does',//，文件
      TextFourteen:' not expire',//不过期
      TextFifteen:', the page is more concise.',//，页面更简洁，适合
      TextSixteen:' large file',//大文件
      TextSeventeen:' sharing.',//分享。
      IncomeModelComparison:'Income model comparison',//收益模式对比
      Equity:'Equity',//权益
      DownloadsRebateModel:'Download rebate model',//下载量返利模式
      VIPRebateModel:'VIP Rebate Model',//会员返利模式
      MoreModes:'More modes coming soon',//更多模式即将上线
      DownloadRebateRules:'Download rebate model rules',//下载量返利模式规则
      RebateMechanism:'1、Rebate mechanism：',//1、返利机制：
      TextEighteen:' According to the level of talent and downloads generated on the day, the amount of rebate revenue will be automatically settled in the morning of the next day.',//按当日达人等级与当日所产生的下载量，在次日凌晨自动结算返利收益金额。
      levelRules:'2、Level rules：',//2、等级规则：
      TextNineteen:'According to the downloads generated yesterday to determine the master rebate level under this model, such as yesterday is downloads reached 302, then today is master level is gold.',//根据昨日所产生的下载量来确定该模式下的达人返利等级，例如昨日下载量达到302，则今日达人等级为黄金。
      doubleRevenue:'3、Double revenue：',//3、双倍收益：
      TextTwenty:'Every Wednesday, Thursday can get double download rebate revenue.',//每周三、周四可获得双倍的下载量返利收益。
      MemberRebateModel:'Member rebate model rules',//会员返利模式规则
      RebateMechanismTwo:'1、Rebate mechanism：',//1、返利机制：
      twentyOne:'After selecting the member rebate mode, other users access through the download link you share, after downloading or transferring files, they will establish a 24-hour binding relationship with you. During the binding period, if the user opens a membership on the web or APP side, you will get ',
      twentyTwo:' of their membership fee as a rebate',//作为返利。
      multiplePurchaseRebate:'2、Multiple purchase rebate：',//2、多次购买返利：
      twentyThree:'Within the 24-hour binding period, if the user opens a VIP for many times,',//在24小时绑定期内，用户若多次开通会员，每次开通会员金额的
      twentyFour:' of the amount of each opened member will be used as your rebate.',//均将作为您的返利。
      BindingRestrictions:'3、Binding restrictions：',//3、绑定关系限制：
      twentyFive:'Once a user has established a binding relationship with you, they will not be able to establish a new binding relationship with other talent within 24 hours.',//一旦用户与您建立了绑定关系，他们在24小时内将无法与其他达人建立新的绑定关系。
      discountCode:'4、Promo code rebate limit：',//4、优惠码返利限制：
      twentySix:"If a user signs up for membership with a promo code, you'll only receive the promo code rebate; membership rebate earnings won't stack.",//如果用户在开通会员时使用了优惠码，平台将根据优惠码的使用情况，向你发放相应的返利收益。
    },
    discountCode:{ //优惠码
      TextOne:'What is the promo code?',//什么是优惠码？
      TextTwo:'Friends through your promo code to open membership to enjoy discounts, while you can get high rebates; The higher the talent level, the larger the discount code.',//好友通过您的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。
      MasterRating:'Master rating',//达人等级
    },
    rebate:{ //下线返利
      TextOne:'What is offline rebate?',//什么是下线返利？
      TextTwo:'When user A invites user B to register, User B becomes offline of User A：',//用户A邀请用户B注册，则用户B成为用户A的下线：
      TextThree:'The Platform will give User A an additional reward of 15% of the revenue obtained by User B in the promotion activities of the Cat Plate, which will continue to be valid.',//平台按用户B在飞猫盘推广活动中获得收益的15%额外给用户A奖励，持续有效。
      MasterRating:'Master rating',//达人等级
    },
    contactCust:{ //联系客服
      HotIssue:'Hot issue',//热门问题
      View:'View',//查看
      AdditionalHelp:'Additional Help',//其他帮助
      SubmitATicket:'Submit a Ticket',//提交工单
      SubmitNow:'Submit Now',//立即提交
      FeedbackHistory:'Feedback History',//反馈历史
      ViewHistory:'View History',//进入查看
      TextOne:'Contact customer service',//联系客服
      TextTwo:'Communicate online now',//立即在线沟通
      TextThree:'Problem not found? Click here to submit',//没找到问题，点此提交问题
      LatestAnnouncements:'Latest Announcements',//最新公告
      FAQ:'FAQ',//常见问题
      Back:'Back',//返回
      LoginRegister:'Login & Register',//登录注册
      WithdrawEarnings:'Income withdrawal',//收益提现
    },
  },
  IncomeReflect: { //余额提现模块
    BalanceWithdrawal:'Balance withdrawal',//余额提现
    WithdrawalRecord:'Withdrawal record',//提现记录
    PayeeManagement:'Payee management',//收款人管理
    tabZero:{ //余额提现
      withdrawableAmount:'Balance',//可提现金额
      yuan:'￥',//元
      Payee:'Payee',//收款人
      NewPayee:'New payee',//新增收款人
      PleaseSelect:'Please select',//请选择
      CashWithdrawal:'Withdrawals',//提现金额
      TextOne:'Please enter the withdrawal amount',//请输入提现金额
      TextTwo:'Minimum ￥30 per withdrawal',//每笔提现最低30元
      phoneNumber:'Phone',//手机号
      PleasePhone:'Please enter your phone number',//请输入手机号
      VerificationCode:'Verification code',//输入验证码
      TextThree:'Please enter the verification code',//请输入验证码
      TextFour:'Retrieve',//获取验证码
      Sent:'Sent',//已发送
      WithdrawCashNow:'Withdraw cash now',//立即提现
      TextFive:'You have not yet done mobile binding, the withdrawal will default after successful Bind this phone number',//您尚未进行手机绑定，提现成功后将默认绑定此手机号
    },
    tabOne:{ //提现记录
      OrderNumber:'Order number',//订单号
      Amount:'Amount',//金额
      Status:'Status',//状态
      Time:'Time',//时间
      TextOne:'Withdrawal record is empty ~',//提现记录为空~
      awaitingProcessing:'Awaiting processing',//待处理
      Successful:'Successful',//成功
      Fail :'Fail',//失败
      ViewCauses :'View Causes',//查看原因
    },
    tabTwo:{ //收款人管理
      NewPayee:'New payee',//新增收款人
      UserNickname:'User nickname',//用户昵称
      AlipayAccount:'Alipay account',//支付宝账号
      IdNumber:'Id number',//身份证号
      TextOne:'Monthly withdrawal limit',//当月提现额度
      Controls:'Controls',//操作
      Editor:'Editor',//编辑
      Delete:'Delete',//删除
      NoRecord:'No record',//暂无记录
    }
  },
  Account: { //账号管理模块
    TextOne:'Consult customer service',//咨询客服了解更多
    ContactCellPhone:'Contact cell phone',//联系手机
    ContactEmail:'Contact email',//联系邮箱
    FEEMOOCertification:'FEEMOO  Certification',//飞猫达人认证
    FEEMOOCertificationTwo:'CloudMaster',//CloudMaster
    SubscriptionNumberOpening:'Subscription number opening',//订阅号开通
    CertifiedFEEMOOMaster:'Certified FEEMOO Master',//已认证飞猫达人
    CertifiedFEEMOOMasterTwo:'Uncertified CloudMaster',//未认证CloudMaster
    TextTwo:'Subscription number has been opened',//已开通订阅号
    TextThree:'Modify Bound Phone',//修改绑定手机
    TextFour:'Modify Bound Mailbox',//修改绑定邮箱
    Accredited:'Accredited',//已认证
    TextFive:'Go to Subscription',//进入订阅号
    BoundPhone:'Bound Phone',//绑定手机
    BoundMailbox:'Bound Mailbox',//绑定邮箱
    UncertifiedFEEMOO:'Uncertified FEEMOO',//未认证飞猫达人
    GetCertified:'Get certified',//去认证
    SubscriptionNotAvailable:'Subscription not available',//未开通订阅号
    GoAndActivate:'Go and Activate',//去开通
    NotActivated:'Not Activated',//未开通
    ActivateNow:'Activate Now',//立即开通
    ActivatedNEW:'Activated',//已开通
  },
  LevelTable: { //等级表格
    ClassInterest:'Class interest',//等级权益
    DownloadsYesterday:'Downloads yesterday',//昨日下载量
    TextOne:'Large file rebates (¥/million)',//大文件返利（元/万次）
    TextTwo:'Rebate for small files (¥/million)',//小文件返利（元/万次）
    CouponCodeDiscount:'Coupon code discount',//优惠码折扣
    PromoCodeRevenue:'Promo code revenue',//优惠码收益
  },
  TablePublic: { //表格组件模块
    UserNickname: 'User nickname', //用户昵称
    OpeningTime: 'Opening time', //开通时间
    RebateAmount: 'Rebate amount', //返利金额
    NoRecord: 'No record', //暂无记录
    QRCode: 'QRCode', //二维码
    InvitationCode: 'Invitation code', //邀请码
    InvitationLink: 'Invitation link', //邀请链接
  },
  MemRbCom: { //会员返利收益组件
    Today: 'Today', //今日
    Yesterday: 'Yesterday', //昨日
    CurrentEarnings: 'Current earnings', //本月收益
    yuan: '￥', //元
  },
  userNew: { // 信息悬浮框模块
    All: 'All', // 全部
    SystemNotification: 'System', // 系统通知
    OfficialNews: 'Official', // 官方消息
    ReadAll: 'Read All', // 全部已读
    Nonews: 'No news', // 暂无消息
  },
}
