<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div
        class="centerBox"
        :style="`backgroundImage:url(${$utils.getPng('darenNewBg2')})`"
      >
        <div class="app-bottom">
          <span class="askTitle">问题反馈</span>
          <span class="describe"
            >需要您描述具体问题或上传附件，提交后客服在7个工作日内回复</span
          >
          <div style="margin-top: 64px">
            <el-form
              ref="form"
              :model="form"
              label-width="108px"
              style="margin-right: 100px"
            >
              <el-form-item>
                <template #label>
                  <span>分类</span>
                  <span style="color: #f53f3f">*</span>
                </template>
                <el-select
                  v-model="sec_tid"
                  @change="change"
                  placeholder="请选择问题分类"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span>标题</span>
                  <span style="color: #f53f3f">*</span>
                </template>
                <el-input
                  class="input"
                  :class="!name ? 'input2' : ''"
                  :maxlength="30"
                  @change="change"
                  placeholder="请输入标题(30字以内)"
                  v-model="name"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span>详细描述</span>
                  <span style="color: #f53f3f">*</span>
                </template>
                <el-input
                  type="textarea"
                  :rows="8"
                  resize="none"
                  @change="change"
                  show-word-limit
                  :maxlength="2000"
                  placeholder="请输入问题描述"
                  v-model="editorContent"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="附件">
                <el-upload
                  class="upload"
                  ref="upload-demo"
                  action=""
                  multiple
                  :limit="limit"
                  :on-exceed="exceed"
                  :before-upload="verify"
                  :file-list="fileList"
                  :show-file-list="false"
                  :http-request="upload"
                >
                  <div class="clickBox">
                    <el-button
                      type="primary"
                      size="medium"
                      class="query"
                      :disabled="fileList.length >= limit"
                      round
                      >点击上传</el-button
                    >
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    <span
                      style="color: #bdbdbe; margin-left: 12px; font-size: 14px"
                      >最多上传{{ limit }}个{{
                        ext.join('、')
                      }}文件，且单个文件不超过{{ maxText }}</span
                    >
                  </div>
                </el-upload>
                <FileList :file-list="fileList" />
              </el-form-item>
            </el-form>
            <div class="btBox">
              <fm-button
                class="submit"
                type="primary"
                :disabled="disabled"
                round
                @click="submit"
                >提交</fm-button
              >
            </div>
          </div>
        </div>
        <Dialog :is-show="dialogVisible" @cancel="cancel" :confirm="confirm" />
      </div>
    </div>
  </div>
</template>
<script>
import { getCspInfo, commitProductionOrder } from '@/utils/apiList/user'
import NavTop from '@/components/NavTop/index.vue'
import Upload from './Upload.vue'
import Dialog from './Dialog.vue'
import FileList from './FileList'
export default {
  mixins: [Upload],
  components: { NavTop, Dialog, FileList },
  data() {
    return {
      editorContent: '', // 文件描述 富文本
      name: '',
      fileList: [],
      isChange: false,
      confirm: () => {},
      isSubmit: false,
      options: [],
      dialogVisible: false,
      ext: [],
      maxText: '',
      maxSize: 0,
      limit: 0,
      top_tid: '',
      sec_tid: '',
    }
  },
  mounted() {
    this.$store.commit('setAttr', {
      name: 'toTop',
      val: this.toTop,
    })
    this.getInfo()
  },
  computed: {
    disabled: function () {
      if (!this.sec_tid) {
        // this.$toast("请选择分类后再提交", 2);
        return true
      }
      if (this.name == '' || this.name.length > 30) {
        // this.$toast("标题不能为空，长度30字以内", 2);
        return true
      }
      if (this.editorContent == '') {
        // this.$toast("内容不能为空", 2);
        return true
      }
      return false
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit) next()
    else if (this.isChange) {
      this.confirm = () => next()
      this.dialogVisible = true
    } else next()
  },
  methods: {
    submit: _.debounce(
      function () {
        if (this.disabled) return
        if (this.fileList.some(item => item.status === 'uploading'))
          return this.$message({
            type: 'warning',
            message: '文件正在上传中，请稍后',
            customClass: 'warning',
          })
        commitProductionOrder({
          top_tid: this.top_tid,
          sec_tid: this.sec_tid,
          title: this.name,
          content: this.editorContent,
          annex: this.fileList,
        }).then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            setTimeout(() => {
              this.isSubmit = true
              this.$router.push({ path: '/help', query: { id: 4 } })
            }, 500)
          } else {
            this.$toast(res.msg)
          }
        })
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    verify(file) {
      if (file.size > this.maxSize) {
        setTimeout(
          () =>
            this.$message({
              type: 'warning',
              message: '上传文件大小超过限制',
              customClass: 'warning',
            }),
          0
        )
        return false
      } else if (
        !this.ext.some(suffix => file.name.toLowerCase().endsWith('.' + suffix))
      ) {
        setTimeout(
          () =>
            this.$message({
              type: 'warning',
              message: '上传文件扩展名是不允许的扩展名',
              customClass: 'warning',
            }),
          0
        )
        return false
      }
    },
    async upload({ file }) {
      this.isChange = true
      const item = {
        file_origin_name: file.name,
        file_size: file.size,
        status: 'uploading',
        path: null,
      }
      this.fileList.push(item)
      const result = await this.asyncUpload(
        file,
        this.isVideo(file.name) ? 14 : 8
      )
      if (result.action === 'success') {
        item.path = result.res.data.path
        item.status = 'success'
      } else {
        item.status = 'fail'
        this.$toast(result.msg || '上传失败')
      }
    },
    isVideo(val) {
      return [
        /.mp4$/i,
        /.flv$/i,
        /.f4v$/i,
        /.webm$/i,
        /.m4v$/i,
        /.mov$/i,
        /.3gp$/i,
        /.3g2$/i,
        /.rm$/i,
        /rmvb$/i,
        /.wmv$/i,
        /.avi$/i,
        /.asf$/i,
        /.mpg$/i,
        /.mpeg$/i,
        /.mpe$/i,
        /.div$/i,
        /.dv$/i,
        /.divx$/i,
        /.vob$/i,
        /.dat$/i,
        /.mkv$/i,
        /.lavf$/i,
        /.cpk$/i,
        /.dirac$/i,
        /.ram$/i,
        /.qt$/i,
        /.fli$/i,
        /.flc$/i,
        /.mod$/,
      ].some(suffix => suffix.test(val))
    },
    getInfo() {
      getCspInfo().then(res => {
        if (res.status == 1) {
          this.options = res.data.type
          this.ext = res.data.uploadConfig.ext.map(suffix =>
            suffix.toLowerCase()
          )
          this.limit = Number(res.data.uploadConfig.upload_limit || 0)
          this.maxSize = Number(res.data.uploadConfig.max_size || 0)
          this.maxText = res.data.uploadConfig.max_size_format
        }
      })
    },
    // 选择投稿分类
    change() {
      this.isChange = true
    },
    exceed() {
      this.$message({
        type: 'warning',
        message: `附件数量最多支持上传${this.limit}个`,
        customClass: 'warning',
      })
    },
    cancel() {
      this.dialogVisible = false
    },
    toTop() {
      if (this.$refs.scrollBox) this.$refs.scrollBox.scrollTop = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .app-box {
    width: 100%;
    height: 100vh;
    min-width: 980px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .centerBox {
      background-size: cover;
      background-position: center 0;
      background-repeat: no-repeat;
    }
    .app-bottom {
      width: 1200px;
      height: calc(100vh - 70px);
      display: flex;
      margin: 0 auto;
      margin-top: 20px;
      background: #fff;
      border-radius: 8px 8px 0px 0px;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      .askTitle {
        font-weight: 500;
        font-size: 20px;
        color: #2c2c2c;
        line-height: 28px;
        margin-bottom: 12px;
      }
      .describe {
        font-weight: 400;
        font-size: 16px;
        color: #8a8b92;
        line-height: 24px;
      }
      ::v-deep .el-form {
        .el-input {
          width: 552px;
          height: 40px;
        }
        .el-form-item {
          // display: flex;
          margin-bottom: 28px;
          .el-form-item__label {
            padding-right: 24px;
            font-weight: 400;
            font-size: 16px;
            color: #2c2c2c;
            line-height: 40px;
          }
        }
        .el-button--primary:hover {
          background: #a39bf6 !important;
          border-color: #a39bf6 !important;
        }
        .el-button--primary:active {
          background: #3213d8 !important;
          border-color: #3213d8 !important;
        }
        .el-button--primary.is-disabled {
          background: #d1c3f9 !important;
          border-color: #d1c3f9 !important;
        }
        .upload {
          display: flex;
          align-items: center;
          .clickBox {
            display: flex;
            align-items: center;
            .query {
              width: 96px;
              height: 36px;
            }
          }
          .el-upload__tip {
            margin-top: 0;
          }
        }
      }
      .btBox {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        .submit {
          width: 240px;
        }
      }
    }
  }
}
</style>
