<!--
 * @FilePath: /daren-html-source/src/components/NavLeft/index.vue
 * @Description: 左侧菜单栏
-->
<template>
  <div class="NavLeft">
    <!-- <div class="navTitle">飞猫达人中心</div> -->
    <div
      class="item"
      v-for="item in navList"
      :key="item.id"
      :class="item.pathArr.indexOf($route.name) >= 0 ? 'act' : ''"
      @click="itemClick(item)"
    >
      <svg-btn :icon-class="'icon-menu-' + item.id" />
      {{ item.pathName }}
    </div>
    <Step1
      :isShow="showStep1"
      @confirm="open()"
      @handleClose="closeStep2()"
    ></Step1>
    <ChangePhoneDiaVue
      ref="ChangePhoneDia"
      @confirm="bindPhoneConfirm()"
      :userInfo="$store.state.userInfo"
      :showChangePhoneFlag="showChangePhoneFlag"
      @handleClose="showChangePhoneFlag = false"
    ></ChangePhoneDiaVue>
    <AddPayee :isShow="showAddpayFlag" @confirm="openDaren" />
  </div>
</template>

<script>
import { openDarenAPINew, userInfoAPI } from '@/utils/apiList/user'
import Step1 from '@/components/OpenDarenDia/step1.vue'
import AddPayee from '@/components/AddPayee/index.vue'
import ChangePhoneDiaVue from '@/components/ChangePhoneDIa/index.vue'
export default {
  data() {
    return {
      //1、左侧取消订阅号入口、
      //2、将收益提现变成一级菜单放在左侧导航栏倒数第二位。
      //3、新的 收益提现 账号管理两个都是一级菜单只有唯一页面，所以取消二级按钮如图所示。
      navList: [
        {
          id: 0,
          // pathName: this.$t('NavLeft.FEEMOO'),
          pathName: 'Feemoo',
          path: '/drive',
          pathArr: ['drive', 'homepage'],
        },
        // {
        //   id: 1,
        //   pathName: "订阅号",
        //   path: "/subscribe",
        //   pathArr: ["subscribe"],
        // },
        {
          id: 2,
          pathName: this.$t('NavLeft.PromotionCode'),
          path: '/memberRebate',
          pathArr: ['memberRebate'],
        },
        {
          id: 3,
          pathName: this.$t('NavLeft.DownlineRebate'),
          path: '/offlineRebate',
          pathArr: ['offlineRebate'],
        },
        {
          id: 4,
          pathName: this.$t('NavLeft.HelpCenter'),
          path: '/help',
          pathArr: ['help'],
        },
        {
          id: 6,
          pathName: this.$t('NavLeft.BalanceWithdrawal'),
          path: '/income',
          pathArr: ['income'],
        },
        {
          id: 5,
          pathName: this.$t('NavLeft.AccountManagement'),
          path: '/account',
          pathArr: ['account'],
        },
      ],
      routeAct: 0,
      showStep1: false,
      showChangePhoneFlag: false,
      showAddpayFlag: false,
    }
  },
  components: {
    Step1,
    ChangePhoneDiaVue,
    AddPayee,
  },
  methods: {
    itemClick(item) {
      if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
        const from = 'daren'
        const fromUrl = this.$glb.darenUrl + item.path
        const encodedFrom = encodeURIComponent(from)
        const encodedFromUrl = encodeURIComponent(fromUrl)
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`
        return
      }
      if (item.id !== 5 && localStorage.getItem('isDaren') == 0) {
        // this.$toast("开通成为飞猫达人即可查看", 2);
        // this.showStep1 = true
        this.open()
      }
      this.$router.push(item.path)
    },
    bindPhoneConfirm() {
      if (this.$store.state.userInfo.is_income_account == 0) {
        this.showChangePhoneFlag = false
        this.showAddpayFlag = true
      } else if (this.$store.state.userInfo.is_rate_flow != 1) {
        this.openDaren()
      }
    },
    open() {
      this.showStep1 = false
      if (this.$store.state.userInfo.is_bind_phone == 0) {
        this.$refs.ChangePhoneDia.step = 2
        this.$refs.ChangePhoneDia.topTit = '绑定手机号'
        this.showChangePhoneFlag = true
        return
      }
      if (this.$store.state.userInfo.is_income_account == 0) {
        this.showAddpayFlag = true
        return
      }
      this.openDaren()
    },
    openDaren() {
      openDarenAPINew()
        .then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            localStorage.setItem('isDaren', 1)
            this.getUser()
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch(err => {})
    },
    getUser() {
      userInfoAPI()
        .then(res => {
          if (res.status == 1) {
            this.$store.commit('setAttr', {
              name: 'userInfo',
              val: res.data,
            })
            localStorage.setItem('isDaren', res.data.is_rate_flow)
            this.$router.push('/drive')
          }
        })
        .catch(err => {})
    },
    closeStep2() {
      this.showStep1 = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
