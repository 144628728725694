import utils from "../index";
import Fingerprint2 from "fingerprintjs2"; // 引入fingerprintjs2
import store from "../../store/index";
import config from "../config/index";
import p from "../secretkey/p";
import router from "@/router";
import { MessageBox, Message } from "element-ui";
import _ from "lodash";
import EquipmentDialog from "@/components/Dialog/EquipmentDialog";


import pfile from "@/utils/secretkey/pfile";
import Vue from "vue";


function createDiv(id) {
  let isExit = document.querySelector("#" + id);
  if (!isExit) {
    let isExit = document.createElement("div");
    isExit.id = id;
    document.body.appendChild(isExit);
  }
  return isExit;
}

function createModel(id, model) {
  createDiv(id);
  const dialogModel = Vue.extend(model);
  return new dialogModel().$mount("#" + id);
}
var aliCodeValidateInstance = null;
var safeDiaInstance = null;
var equipmentInstance = null;

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak) };
  } else {
    return data;
  }
}
// 引用axios
var axios = require("axios");
var root = process.env.NODE_ENV === "production" ? config.getApiUrl : "/api/";

async function createFingerprint() {
  // 选择哪些信息作为浏览器指纹生成的依据
  const options = {
    fonts: {
      extendedJsFonts: true,
    },
    excludes: {
      audio: true,
      userAgent: true,
      enumerateDevices: true,
      touchSupport: true,
    },
  };

  // 使用 Promise 包装 Fingerprint2.get，以便能够等待它完成
  const fingerprint = new Promise((resolve) => {
    Fingerprint2.get(options, (components) => {
      const values = components.map((component) =>
        component.key != "pixelRatio" ? component.value : ""
      );
      const murmur = Fingerprint2.x64hash128(values.join(""), 31);
      resolve(murmur);
    });
  });
  // 1. 获取12位的aes key
  var ak = utils.genak();

  const murmur = await fingerprint;

  let dataa = {
    device_key: murmur,
  };
  var pto2 = utils.re(ak, unescape(escape(p)));
  var encrPair2 = utils.secret(dataa, ak);
  if (!localStorage.getItem("encrPair2")) {
    localStorage.setItem("encrPair2", encrPair2);
    localStorage.setItem("pto2", pto2);
  }
  //清除迭代版本的encrPair，pto
  if (localStorage.getItem("encrPair")) {
    localStorage.removeItem("encrPair");
    localStorage.removeItem("pto");
  }
}
const app = new Vue({});
var h = app.$createElement;

// async function getUser() {
//   try {
//     const response = await axios.post(`${root}user-service/user/info`);
//   } catch (error) {
//     console.error(error);
//   }
// }
//想加全局控制加密的开关
// await getUser();

function getAllRouteList(routes, names) {
  names = names || [];
  routes.forEach((route) => {
    if (route.name) {
      names.push({
        name: route.name,
        requireLogin: route.meta.requireLogin,
        path: route.path,
      });
    }
    if (route.children) {
      getAllRouteList(route.children, names);
    }
  });
  return names;
}
let RouterList = [];
let currentParh = "";
async function getRouter() {
  setTimeout(() => {
    RouterList = getAllRouteList(router.options.routes);
  }, 0);
}

const checkRouter = _.debounce(
  async () => {
    try {
      const Item = await new Promise((resolve) => {
        const foundItem = RouterList.find((item) => item.name == router.currentRoute.name);
        resolve(foundItem);
      });
      currentParh = Item?.name || "";
    } catch (error) {
      console.error('Error in checkRouter:', error);
    }
  },
  500,
  {
    leading: true,
    trailing: false,
  }
);



const messageShow = _.debounce(
  (msg = "请先登录") => {
    Message({
      type: "warning",
      message: h(
        "div",
        {
          style:
            "padding-right:20px display: flex;align-items: center;color: #F53F3F; background: #FFECE8;",
        },
        [h("span", null, msg)]
      ),
    });
  },
  300,
  {
    leading: true,
    trailing: false,
  }
);

const xiaxianDiaShow = _.debounce(
  () => {
    equipmentInstance = createModel("equipmentDia", EquipmentDialog);
    equipmentInstance.handleOpen();
  },
  1000,
  {
    leading: true,
    trailing: false,
  }
);

const msgBoxAlert = _.debounce(
  (res) => {
    MessageBox.alert("页面加载错误，请刷新。错误代码: " + res?.status, "提示", {
      confirmButtonText: "刷新页面",
      callback: (action) => {
        // confirm cancel
        if (action === "confirm") {
          window.location.reload();
        }
      },
    });
  },
  300,
  {
    leading: true,
    trailing: false,
  }
);

const RouterPush = _.debounce(
  () => {
    utils.clearCookie(config.fmCookieName);
  },
  300,
  {
    leading: true,
    trailing: false,
  }
);

await createFingerprint();
await getRouter();
// await geInst();

//1. 创建axios对象
const service = axios.create({
  baseURL: root,
  timeout: 30000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    par: localStorage.getItem("encrPair2"),
    pto: localStorage.getItem("pto2"),
    os: "pc",
    AuthToken: utils.getCookie(config.fmCookieName) || '',
  },
});

let conf = config
//2. 请求拦截器
service.interceptors.request.use(
  (config) => {
    let lang = localStorage.getItem('lang')
    config.headers['fm-lang'] = lang == 'en' ? 'en-US' : lang == 'zhhk'? 'zh-HK':'zh-CN'
    config.headers.AuthToken = utils.getCookie(conf.fmCookieName) || ''
    if (config.method === "post") {
      // 将 JSON 数据转换为 FormData
      const formData = new FormData();
      for (const key in config.data) {
        formData.append(key, config.data[key]);
      }
      // 更新请求配置，使用 FormData 格式的数据
      config.data = formData;

      // 更新请求头，指定使用 FormData 格式
      // config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//3. 响应拦截器
service.interceptors.response.use(
  async (response) => {
    //接口返回需要登录的时候重置页面到登录页,清除cookie
    if (response.data?.msg == '请先登录') {
      if (currentParh != 'homepage') {
        RouterPush()
      }
    }
    if (response.data?.status == 1) {
      equipmentInstance?.handleClose();
    }
    if (response.data?.status == 10020) {
      store.commit("clearUserData");
      utils.clearCookie(config.fmCookieName);

      if (currentParh != 'homepage') {
        router.push({
          path: "/homepage",
        })
      }
    }
    if (response.data?.status == 5000) {
      messageShow(response.data.msg);
    }
    if (response.data?.status == 1012) {
      store.commit("clearUserData");
      utils.clearCookie(config.fmCookieName);
      xiaxianDiaShow();
      response.data.msg = "";
    }
    //清空pto2
    if (response.data?.status == 40100) {
      localStorage.removeItem("encrPair2");
      localStorage.removeItem("pto2");
      createFingerprint();
    }
    // if (response.data?.status == 90001 && !response.config.validateAxios) {
    //   if (!aliCodeValidateInstance) {
    //     aliCodeValidateInstance = createModel(
    //       "aliCodeValidate",
    //       aliCodeValidate
    //     );
    //   }
    //   async function alyAgain(captcha_verify) {
    //     let result = {
    //       result: false,
    //       validate: true,
    //     };
    //     const originalRequest = response.config;
    //     try {
    //       originalRequest.baseURL = "";
    //       const newResponse = await service({
    //         ...originalRequest,
    //         data: inParameter({
    //           ...response.data.params,
    //           captcha_verify: captcha_verify || "",
    //         }),
    //         validateAxios: 1,
    //       });
    //       // Handle the new response as needed
    //       let __status = Number(newResponse.status);
    //       if (__status === 1) {
    //         result.result = true;
    //         result.data = newResponse;
    //       } else {
    //         result.validate = __status !== 90001;
    //       }
    //     } catch (error) {}
    //     return result;
    //   }
    //   let validateRes = await aliCodeValidateInstance.handleOpenSync(alyAgain);
    //   if (validateRes.result) {
    //     return validateRes.data;
    //   }
    // }

    return response.data;
  },
  (err) => {
    let res = err.response;
    if (res) {
      // msgBoxAlert(res);
    }

    return {
      status: -1,
    };
    // return Promise.reject(error);
  }
);

export default service;
