<template>
  <div>
    <Detail @customEvent="$emit('customEvent', $event)" v-if="isDisplay === 1" :detailId="detailId" />
    <History @customEvent="$emit('customEvent', $event)" v-if="isDisplay === 2" />
  </div>
</template>

<script>
import Detail from "./detail.vue";
import History from "./history.vue";
export default {
  components: {
    Detail,
    History,
  },
  props:{
    isDisplay: {
      type: Number,
      default: 0,
    },
    detailId: {
      type: Number || String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {}
}
</script>
