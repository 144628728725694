<!--
 * @FilePath: /daren-html-source/src/views/Account/userInfo.vue
 * @Description: 账户信息
-->
<template>
  <div class="userInfo">
    <div class="top">
      <div class="left">
        <img class="logo" :src="userData.logo" alt="" />
        <div class="nickName">{{ userData.nickname }}</div>
      </div>

      <fm-button
        icon="icon6"
        class="kfbtn"
        type="primary"
        plain
        @click="tokefu()"
        >{{ $t('Account.TextOne') }}</fm-button
      >
    </div>
    <div class="center">
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_new_user0`)" alt="" />
          <div class="name">{{ $t('Account.ContactCellPhone') }}</div>
        </div>

        <div class="intr">{{ $utils.hidePhone(userData.phone) }}</div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            type="info"
            round
            plain
            @click="changePhone()"
          >
            {{
              userData?.phone
                ? $t('Account.TextThree')
                : $t('Account.BoundPhone')
            }}</fm-button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_new_user1`)" alt="" />
          <div class="name">{{ $t('Account.ContactEmail') }}</div>
        </div>

        <div class="intr">{{ $utils.regEmail(userData.email) }}</div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            @click="changeEmail()"
            type="info"
            round
            plain
            >{{
              userData.email
                ? $t('Account.TextFour')
                : $t('Account.BoundMailbox')
            }}</fm-button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_new_user2`)" alt="" />
          <div class="name">{{ $t('Account.FEEMOOCertificationTwo') }}</div>
        </div>

        <div class="intr">
          {{
            is_rate_flow == 1
              ? ''
              : $t('Account.NotActivated')
          }}
        </div>
        <div :class="is_rate_flow == 1?'btnBox1':'btnBox'">
          <fm-button
            v-if="!loading"
            class="btn btn1"
            size="medium"
            :style="is_rate_flow == 1 ? ' color: #7E72F6;' : ''"
            :type="is_rate_flow == 1 ? 'text' : 'info'"
            round
            plain
            @click="is_rate_flow == 1 ? ' ' : toOpenDaren(true)"
            >{{
              is_rate_flow == 1
                ? $t('Account.ActivatedNEW')
                : $t('Account.ActivateNow')
            }}</fm-button
          >
        </div>
      </div>
      <!-- <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_user3`)" alt="" />
          <div class="name">{{ $t('Account.SubscriptionNumberOpening') }}</div>
        </div>

        <div class="intr">
          {{
            is_subscribe == 1
              ? $t('Account.TextTwo')
              : $t('Account.SubscriptionNotAvailable')
          }}
        </div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            type="info"
            round
            plain
            @click="toSub()"
            >{{
              is_subscribe == 1
                ? $t('Account.TextFive')
                : $t('Account.GoAndActivate')
            }}</fm-button
          >
        </div>
      </div> -->
      <ChangePhoneDiaVue
        ref="ChangePhoneDia"
        :userInfo="userData"
        @confirm="bindPhoneConfirm()"
        :showChangePhoneFlag="showChangePhoneFlag"
        @handleClose="showChangePhoneFlag = false"
      ></ChangePhoneDiaVue>
      <RealName
        :isShow="showRealNameFlag"
        :type="2"
        @handleClose="showRealNameFlag = false"
        @confirmNameAuth="realNameAuth"
      ></RealName>
      <ChangeEmail
        ref="ChangeEmailDia"
        :userInfo="userData"
        :showChangePhoneFlag="showChangeEmailFlag"
        @handleClose="showChangeEmailFlag = false"
      ></ChangeEmail>
      <OpenSubNoDaren
        :isShow="noDareFlag"
        @confirm="toOpenDaren(false)"
        @handleClose="noDareFlag = false"
      ></OpenSubNoDaren>
      <AddPayee :isShow="showAddpayFlag" @confirm="openDaren"/>
    </div>
  </div>
</template>

<script>
import { openDarenAPINew } from '@/utils/apiList/user'
import ChangePhoneDiaVue from '@/components/ChangePhoneDIa/index.vue'
import RealName from '@/components/ChangePhoneDIa/realName.vue'
import ChangeEmail from '@/components/ChangeEmail/index.vue'
import OpenSubNoDaren from '@/components/OpenSubNoDaren/index.vue'
import AddPayee from '@/components/AddPayee/index.vue'
import { userInfoAPI, realNameAuth } from '@/utils/apiList/user.js'

export default {
  components: {
    ChangePhoneDiaVue,
    RealName,
    ChangeEmail,
    OpenSubNoDaren,
    AddPayee
  },
  data() {
    return {
      userList: [
        {
          id: 0,
          name: this.$t('Account.ContactCellPhone'),
          intr: '',
          btnTit: this.$t('Account.BoundPhone'),
        },
        {
          id: 1,
          name: this.$t('Account.ContactEmail'),
          intr: '',
          btnTit: this.$t('Account.BoundMailbox'),
        },
        {
          id: 2,
          name: this.$t('Account.FEEMOOCertification'),
          intr: this.$t('Account.UncertifiedFEEMOO'),
          btnTit: this.$t('Account.GetCertified'),
        },
        {
          id: 3,
          name: this.$t('Account.SubscriptionNumberOpening'),
          intr: this.$t('Account.SubscriptionNotAvailable'),
          btnTit: this.$t('Account.GoAndActivate'),
        },
      ],
      userData: {},
      showChangePhoneFlag: false,
      showRealNameFlag: false,
      showChangeEmailFlag: false,
      noDareFlag: false,
      showAddpayFlag: false,
      loading: false,
      darenProcess: false,
      jump: false
    }
  },
  computed: {
    is_rate_flow() {
      return this.userData.is_rate_flow
    },
    is_subscribe() {
      return this.userData.is_subscribe
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    changePhone() {
      if (this.userData.phone) {
        this.$refs.ChangePhoneDia.step = 1
        this.$refs.ChangePhoneDia.topTit = '修改手机号'
      } else {
        this.$refs.ChangePhoneDia.step = 2
        this.$refs.ChangePhoneDia.topTit = '绑定手机号'
      }
      this.showChangePhoneFlag = true
    },
    changeEmail() {
      if (this.userData.email) {
        this.$refs.ChangeEmailDia.step = 1
        this.$refs.ChangeEmailDia.topTit = '修改邮箱'
      } else {
        this.$refs.ChangeEmailDia.step = 2
        this.$refs.ChangeEmailDia.topTit = '绑定邮箱'
      }
      this.showChangeEmailFlag = true
    },
    bindPhoneConfirm() {
      if(this.darenProcess) {
        this.darenProcess = false
        if (this.userData.is_income_account == 0) {
          this.showChangePhoneFlag = false
          this.showAddpayFlag = true
        } else if(this.userData.is_rate_flow != 1){
          this.openDaren()
        }  
      }
    },
    openDaren() {
      openDarenAPINew()
        .then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            localStorage.setItem('isDaren', 1)
            if(this.jump) {
              this.$router.push('/drive')
            }
            else this.getUser()
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch(err => {}) 
    },
    getUser() {
      this.loading = true
      userInfoAPI()
        .then(res => {
          if (res.status == 1) {
            this.userData = res.data
            this.$store.commit('setAttr', {
              name: 'userInfo',
              val: res.data,
            })
            this.loading = false
            localStorage.setItem('isDaren', res.data.is_rate_flow)
          }
        })
        .catch(err => {})
    },

    realNameAuth(data) {
      realNameAuth(data).then(res => {
        if (res.status == '1') {
          this.showRealNameFlag = false
          this.getUser()
          this.$toast(res.msg, 1)
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
    toOpenDaren(jump=true) {
      this.jump = jump
      this.noDareFlag = false
      this.darenProcess = true
      if(!this.userData.phone){
        this.changePhone()
        return
      }else if(this.userData.is_income_account == 0){
        this.showAddpayFlag = true
        return
      }
      this.openDaren()
      // this.$router.push({
      //   name: 'homepage',
      //   params: {
      //     flag: this.userData.phone == '' ? 2 : this.userData.is_income_account,
      //   },
      // })
    },
    toSub() {
      if (this.userData.is_rate_flow == 1) {
        let _url = this.$glb.upJmdyhUrl + '/subscribe'
        let uInfo = this.$store.state.userInfo || {}
        if (uInfo.token) {
          _url += '?' + 'platfro=' + uInfo.token
        }
        window.open(_url)
      } else {
        this.noDareFlag = true
      }
    },
    tokefu() {
      window.open('http://q.url.cn/CDKEvl?_type=wpa&qidian=true')
    },
  },
}
</script>

<style lang="scss" scoped>
.userInfo {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  .top {
    display: flex;
    align-items: center;
    padding-top: 8px;
    .left {
      display: flex;
      align-items: center;
      height: 72px;

      width: 100%;
      .logo {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        object-fit: cover;
      }
      .nickName {
        height: 24px;
        font-weight: 500;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
        margin-left: 16px;
      }
    }
    .kfbtn {
      border: 1px solid #7E72F6;
      // width: auto;
      // min-width: 184px;
      height: 40px;
      border-radius: 10px;
      font-size: 14px;
    }
  }
  .center {
    margin-top: 64px;
    .item {
      display: flex;
      height: 88px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
      align-items: center;
      .itemLeft {
        display: flex;
        height: 88px;
        width: 136px;
        align-items: center;
        color: #2c2c2c;
        .icon {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
        .name {
          white-space: nowrap;
          width: 88px !important;
        }
      }
      .intr {
        height: 22px;
        font-weight: 400;
        font-size: 14px;
        color: #8a8b92;
        line-height: 22px;
        margin-left: 197px;
      }
      .btnBox {
        margin-left: auto;
        margin-right: 0;
        .btn {
          min-width: 132px;
        }
        :hover {
          background: #f6f6f6;
        }
      }
      .btnBox1 {
        margin-left: auto;
        margin-right: 0;
        .btn {
          width: 132px;
          cursor: auto;
        }
      }
    }
  }
}
</style>
