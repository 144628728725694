/*
 * @FilePath: /daren-html-source/src/utils/apiList/user.js
 * @Description: 
 */
import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";
var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥
function decrypt(data) {
    if (data?.ak) {
        let plaintext = utils.rd(data.ak, unescape(sfile));
        let obj = utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
    } else {
        return data;
    }
}
function inParameter(data) {
    if (data) {
        return { sn: ed, jt: utils.secret(data, ak) };
    } else {
        return data;
    }
}
//用户信息
export function userInfoAPI(data) {
    return request({
        url: "/user/getUserInfo",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//获取收款人列表 
export function getPayeeListAPI(data) {
    return request({
        url: "/rateFlowAccount/getPayeeList",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//发动短信验证码
export function sendCodeAPI(data) {
    return request({
        url: "/user/sendMsg",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//新增收款人
export function updatePayeeAPI(data) {
    return request({
        url: "/rateFlowAccount/updatePayee",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//新增收款人new
export function updatePayeeAPINew(data) {
  return request({
      url: "/rateFlowAccount/updatePayeeV2",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//新增收款人
export function dletePayeeAPI(data) {
    return request({
        url: "/rateFlowAccount/deletePayee",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//提现
export function toCashAPI(data) {
    return request({
        url: "/rateFlowAccount/toCash",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//提现
export function getIncomelistAPI(data) {
    return request({
        url: "/rateFlowAccount/incomelist",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//登录
export function LoginSSOAPI(data) {
    return request({
        url: `/sso/login?&token=${data.token}`,
        method: "get",
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function userMsg(data) {
    return request({
        url: "/userMsg/detailsList",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function userMsgread(data) {
    return request({
        url: "/userMsg/read",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function userMsginfoapi(data) {
    return request({
        url: "/userMsg/info",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
//旧手机号码发送短信
export function beforePhoneSendCodeAPI(data) {
    return request({
        url: "/user/beingPhoneSendCode",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//旧手机号码校验
export function beforePhoneCodeCheck(data) {
    return request({
        url: "/user/beingPhoneCodeCheck",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//绑定手机
export function bindPhoneAPI(data) {
    return request({
        url: "/user/bindPhone",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//绑定手机发送短信验证码
export function bindPhoneSendCode(data) {
    return request({
        url: "/user/bindPhoneSendCode",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getSliderInfoAPI(data) {
    return request({
        url: "/slider/sliderInfo",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//发送邮箱验证码
export function sendEmailModifyAPI(data) {
    return request({
        url: "/user/sendEmailModify",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data);
        return res;
    })
        .catch((err) => {
            console.log(err);
        });
}
//绑定邮箱
export function bindEmailAPI(data) {
    return request({
        url: "/user/bindEmail",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data);
        return res;
    })
        .catch((err) => {
            console.log(err);
        });
}
export function beingEmailCodeCheckAPI(data) {
    return request({
        url: "/user/beingEmailCodeCheck",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

//开通达人
export function openDarenAPI(data) {
    return request({
        url: "/rateFlowAccount/activate",
        method: "post",
        data: inParameter(data),
    }).then((res) => {
        res.data = decrypt(res.data);
        return res;
    })
        .catch((err) => {
            console.log(err);
        });
}
//开通达人new
export function openDarenAPINew(data) {
  return request({
      url: "/rateFlowAccount/activateV2",
      method: "post",
      data: inParameter(data),
  }).then((res) => {
      res.data = decrypt(res.data);
      return res;
  })
      .catch((err) => {
          console.log(err);
      });
}
//实名认证
export function realNameAuth(data) {
    return request({
        url: "/user/realNameAuth",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function loginOutAPI(url, data) {
    return request({
        url: "/user/loginOut",
        method: "post",
        data
    }).catch((err) => { });
}
//帮助中心
export function getQuestionsAPI(url, data) {
    return request({
        url: "helpCenter/getQuestions",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
export function getQuestionsV2(url, data) {
  return request({
      url: "helpCenter/getQuestionsV2",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//获取区号
export function getAreaCode(data) {
  return request({
      url: "/user/getPhonePcode",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//获取客服问题详情
export function getAskDetail(data) {
  return request({
      url: "/helpCenter/getQuestionsDetail",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//获取反馈历史列表
export function getProductList(data) {
  return request({
      url: "/helpCenter/getProductionList",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//获取问题分类
export function getCspInfo(data) {
  return request({
      url: "/helpCenter/getCspCategory",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//获取上传链接
export function getDirectAvatarUploadLinkAPI(data) {
  return request({
      url: "/file/getDirectAvatarUploadLink",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//提交工单
export function commitProductionOrder(data) {
  return request({
      url: "/helpCenter/commitProductionOrder",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//提交工单
export function getQuestionsDetail(data) {
  return request({
      url: "/helpCenter/productOrderDetail",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
//提交工单
export function reply(data) {
  return request({
      url: "/helpCenter/replyProduct",
      method: "post",
      data: inParameter(data),
  })
      .then((res) => {
          res.data = decrypt(res.data);
          return res;
      })
      .catch((err) => {
          console.log(err);
      });
}
