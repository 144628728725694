<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div
        class="centerBox"
        ref="scrollBox"
        :style="`backgroundImage:url(${$utils.getPng('darenNewBg2')})`"
      >
        <div class="app-bottom">
          <div class="feedbackHistory">
            <div class="historyTitle">反馈详情</div>
            <div class="topline">
              <div class="topTit">{{ feedbackTemp.tag_name || '' }}</div>
              <div class="botTit">{{ feedbackTemp.title }}</div>
            </div>
            <div class="recordListBox">
              <div class="record" v-for="item in list" :key="item">
                <div class="titleD">
                  <span class="label">{{
                    item.is_user === '1' ? '问题描述：' : '客服回复：'
                  }}</span>
                  <span class="time">{{ item.in_time }}</span>
                </div>
                <div v-html="item.content" class="content"></div>
                <div class="zwDiv"></div>
                <FileList :file-list="item.annex" readonly />
              </div>
            </div>
            <div
              class="btBigBox"
              v-if="feedbackTemp.status === '0' || feedbackTemp.status === '1'"
            >
              <fm-button
                v-if="!show"
                class="btnSend"
                type="primary"
                round
                @click="handleReply"
                >回复</fm-button
              >
              <template v-else>
                <el-form
                  label-position="top"
                  label-width="80px"
                  :model="formLabelAlign"
                >
                  <el-form-item>
                    <template #label>
                      <span>回复内容</span>
                      <span style="color: #f53f3f">*</span>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="8"
                      :maxlength="2000"
                      @change="change"
                      resize="none"
                      show-word-limit
                      placeholder="请输入问题描述"
                      v-model="editorContent"
                    >
                    </el-input>
                    <!-- <el-input v-model="editorContent "></el-input> -->
                  </el-form-item>
                  <el-form-item label="附件">
                    <el-upload
                      class="upload"
                      ref="upload-demo"
                      action=""
                      multiple
                      :limit="limit"
                      :on-exceed="exceed"
                      :before-upload="verify"
                      :file-list="fileList"
                      :show-file-list="false"
                      :http-request="upload"
                    >
                      <div style="display: flex; align-items: center">
                        <el-button
                          type="primary"
                          size="medium"
                          :disabled="fileList.length >= limit"
                          round
                          >点击上传</el-button
                        >
                      </div>
                      <div slot="tip">
                        <span
                          style="
                            color: #bdbdbe;
                            margin-left: 12px;
                            font-size: 14px;
                          "
                          >最多上传{{ limit }}个{{
                            ext.join('、')
                          }}文件，且单个文件不超过{{ maxText }}</span
                        >
                      </div>
                    </el-upload>
                    <FileList :file-list="fileList" />
                  </el-form-item>
                </el-form>
                <div class="btSmallBox">
                  <fm-button
                    class="submit"
                    type="primary"
                    :disabled="!editorContent"
                    round
                    @click="submit"
                    >提交</fm-button
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
        <Dialog :is-show="dialogVisible" @cancel="cancel" :confirm="confirm" />
      </div>
    </div>
  </div>
</template>
<script>
import { getQuestionsDetail, reply } from '@/utils/apiList/user'
import NavTop from '@/components/NavTop/index.vue'
import Upload from '../workOrder/Upload.vue'
import Dialog from '../workOrder/Dialog.vue'
import FileList from '../workOrder/FileList'
export default {
  mixins: [Upload],
  components: { NavTop, Dialog, FileList },
  data() {
    return {
      input: '',
      show: false,
      fileList: [],
      dialogVisible: false,
      isChange: false,
      confirm: () => {},
      editorContent: '', // 文件描述 富文本
      newUpType: 9,
      ext: [],
      maxText: '',
      maxSize: 0,
      limit: 0,
      feedbackId: null,
      feedbackTemp: {},
      replying: false,
      editor: null,
      formLabelAlign: {},
    }
  },
  mounted() {
    this.$store.commit('setAttr', {
      name: 'toTop',
      val: this.toTop,
    })
    this.feedbackId = this.$route.query.id || null
    if (!this.feedbackId) {
      this.$router.push({ path: '/help', query: { isHis: true } })
    }
    this.getQuestionsDetail()
  },
  computed: {
    list: function () {
      return this.feedbackTemp.list || []
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChange) {
      this.confirm = () => next()
      this.dialogVisible = true
    } else next()
  },
  methods: {
    handleReply() {
      this.show = true
      this.toBottom()
    },
    toBottom() {
      this.$nextTick(() => {
        const scrollBox = this.$refs.scrollBox
        if (scrollBox) {
          scrollBox.scrollTo({
            top: scrollBox.scrollHeight,
            behavior: 'smooth',
          })
        }
      })
    },
    submit: _.debounce(
      async function () {
        // this.show = false
        // return
        if (!this.editorContent) return
        if (this.fileList.some(item => item.status === 'uploading'))
          return this.$message({
            type: 'warning',
            message: '文件正在上传中，请稍后',
            customClass: 'warning',
          })
        if (this.replying || this.disabled) return false
        this.replying = true
        try {
          let res = await reply({
            id: this.feedbackId,
            content: this.editorContent,
            annex: this.fileList,
          })
          this.$toast(res.msg, res.status == 1 ? 1 : 2)
          if (res.status == 1) {
            this.editorContent = ''
            this.fileList = []
            this.isChange = false
            await this.getQuestionsDetail()
            this.show = false
            this.toBottom()
          }
        } catch (e) {}
        this.replying = false
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    verify(file) {
      if (file.size > this.maxSize) {
        setTimeout(
          () =>
            this.$message({
              type: 'warning',
              message: '上传文件大小超过限制',
              customClass: 'warning',
            }),
          0
        )
        return false
      } else if (
        !this.ext.some(suffix => file.name.toLowerCase().endsWith('.' + suffix))
      ) {
        setTimeout(
          () =>
            this.$message({
              type: 'warning',
              message: '上传文件扩展名是不允许的扩展名',
              customClass: 'warning',
            }),
          0
        )
        return false
      }
    },
    async upload({ file }) {
      this.isChange = true
      const item = {
        file_origin_name: file.name,
        file_size: file.size,
        status: 'uploading',
        path: null,
      }
      this.fileList.push(item)
      const result = await this.asyncUpload(
        file,
        this.isVideo(file.name) ? 14 : 9
      )
      if (result.action === 'success') {
        item.path = result.res.data.path
        item.status = 'success'
      } else {
        item.status = 'fail'
        this.$toast(result.msg || '上传失败')
      }
    },
    isVideo(val) {
      return [
        /.mp4$/i,
        /.flv$/i,
        /.f4v$/i,
        /.webm$/i,
        /.m4v$/i,
        /.mov$/i,
        /.3gp$/i,
        /.3g2$/i,
        /.rm$/i,
        /rmvb$/i,
        /.wmv$/i,
        /.avi$/i,
        /.asf$/i,
        /.mpg$/i,
        /.mpeg$/i,
        /.mpe$/i,
        /.div$/i,
        /.dv$/i,
        /.divx$/i,
        /.vob$/i,
        /.dat$/i,
        /.mkv$/i,
        /.lavf$/i,
        /.cpk$/i,
        /.dirac$/i,
        /.ram$/i,
        /.qt$/i,
        /.fli$/i,
        /.flc$/i,
        /.mod$/,
      ].some(suffix => suffix.test(val))
    },
    async getQuestionsDetail() {
      if (!this.feedbackId) return false
      try {
        let res = await getQuestionsDetail({
          id: this.feedbackId,
        })
        if (res.status == 1) {
          this.feedbackTemp = res.data
          this.ext = res.data.upload_config.ext.map(suffix =>
            suffix.toLowerCase()
          )
          this.limit = Number(res.data.upload_config.upload_limit || 0)
          this.maxSize = Number(res.data.upload_config.max_size || 0)
          this.maxText = res.data.upload_config.max_size_format
        }
      } catch (e) {}
    },
    // 选择投稿分类
    change() {
      this.isChange = true
    },
    exceed() {
      this.$message({
        type: 'warning',
        message: `附件数量最多支持上传${this.limit}个`,
        customClass: 'warning',
      })
    },
    cancel() {
      this.dialogVisible = false
    },
    toTop() {
      if (this.$refs.scrollBox) this.$refs.scrollBox.scrollTop = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .app-box {
    width: 100%;
    height: 100vh;
    min-width: 980px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .centerBox {
      background-size: cover;
      background-position: center 0;
      background-repeat: no-repeat;
      overflow: auto;
    }
    .app-bottom {
      width: 1200px;
      min-height: calc(100vh - 70px);
      display: flex;
      margin: 0 auto;
      margin-top: 20px;
      background: #fff;
      border-radius: 8px 8px 0px 0px;
      flex-direction: column;
      align-items: center;
      .feedbackHistory {
        width: 100%;
        height: 100%;
        padding: 40px 32px 72px 32px;
        // overflow: auto;
        .el-button--primary:hover {
          background: #a39bf6 !important;
          border-color: #a39bf6 !important;
        }
        .el-button--primary:active {
          background: #3213d8 !important;
          border-color: #3213d8 !important;
        }
        .el-button--primary.is-disabled {
          background: #d1c3f9 !important;
          border-color: #d1c3f9 !important;
        }
        .historyTitle {
          text-align: center;
          padding-bottom: 24px;
          font-size: 20px;
          color: #2c2c2c;
          line-height: 28px;
          font-weight: 500;
        }
        .topline {
          padding: 32px 0;
          margin-bottom: 24px;
          .topTit {
            font-weight: 600;
            font-size: 20px;
            color: #2c2c2c;
            line-height: 28px;
            margin-bottom: 16px;
            word-wrap: break-word;
            word-break: break-all;
          }
          .botTit {
            font-weight: 400;
            font-size: 18px;
            color: #2c2c2c;
            line-height: 26px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: normal;
          }
        }
        .recordListBox {
          .record {
            padding-bottom: 32px;
            margin-bottom: 24px;
            box-shadow: inset 0px -0.5px 0px 0px #E5E5E5;
            &:last-child {
              margin-bottom: 12px !important;
              box-shadow: none !important;
            }
            .titleD {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 16px;
              .label {
                font-weight: 500;
                font-size: 16px;
                color: #7e72f6;
                line-height: 24px;
              }
              .time {
                font-weight: 400;
                font-size: 14px;
                color: #8a8b92;
                line-height: 22px;
              }
            }
            .content {
              font-weight: 400;
              font-size: 16px;
              color: #2c2c2c;
              line-height: 24px;
              word-wrap: break-word;
              word-break: break-all;
            }
            .zwDiv {
              height: 14px;
              background: transparent;
            }
          }
        }
        .btBigBox {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .btnSend {
            width: 240px;
          }
          ::v-deep .el-form {
            width: 100%;
            .el-form-item__label {
              font-weight: 500;
              font-size: 16px;
              color: #2c2c2c;
              line-height: 24px;
              padding-bottom: 16px;
            }
          }
          .btSmallBox {
            width: 100%;
            display: flex;
            justify-content: center;
            .submit {
              width: 240px;
            }
          }
        }
      }
    }
  }
}
</style>
