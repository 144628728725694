<template>
  <div class="historyBox">
    <div class="headBox">
      <div class="backBox" @click="back">
        <svg-btn class="backSvg" icon-class="icon-back-left" />
        <span class="backFont">返回</span>
      </div>
      <div class="titleFont">反馈历史</div>
    </div>
    <div v-loading="loadingFlag">
      <div v-show="total > 0">
        <div v-for="(item, index) in historyList" :key="index" class="history" @click="toLink(item)">
        <div class="rightBox">
          <div :class="getStatusClass(item.status)">
            <span class="status">{{ getStatusText(item.status) }}</span>
            <span class="red" v-if="item.new_reply == 1"></span>
          </div>
          <span class="title">{{ item.title }}</span>
        </div>
        <span class="time">{{ item.in_time }}</span>
        </div>
      </div>
      <div class="empty" v-show="total == 0">
        <img class="emptyImg" :src="$utils.getPng('icon_new_empty')" alt="" />
        <div class="emptyText">暂无反馈历史</div>
      </div>
      <div class="pagation" v-show="total > 25">
        <PaginationCom 
          :total="total" 
          :pageSize="25"
          @changepage="changepage">
        </PaginationCom>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList } from '@/utils/apiList/user'
import PaginationCom from '@/components/pagination/index.vue'
export default {
  components: {
    PaginationCom,
  },
  data() {
    return {
      total: null,
      page: 1,
      historyList: [],
      loadingFlag:true,
    }
  },
  mounted() {
    this.getProduct()
  },
  computed: {
    getStatusClass() {
      return (status) => {
        switch (Number(status)) {
          case 2:
            return 'statusBox'
          case 0:
            return 'statusBox2'
          case 1:
            return 'statusBox3'
          default:
            return ''
        }
      }
    },
    getStatusText() {
      return (status) => {
        switch (Number(status)) {
          case 0:
            return '待处理'
          case 1:
            return '处理中'
          case 2:
            return '已结束'
          default:
            return ''
        }
      }
    }
  },
  methods: {
    getProduct() {
      this.loadingFlag = true
      getProductList({ page: this.page, limit: 25 }).then(res => {
        if (res.status == 1) {
          this.loadingFlag = false
          this.historyList = res.data.list
          this.total = parseInt(res.data.total)
        }
      })
    },
    toLink(item){
      window.open(`/FeedbackHistory?id=`+item.id, '_blank')
    },
    changepage(page) {
      this.page = page
      this.getProduct()
    },
    back() {
      this.$emit('customEvent')
    },
  },
}
</script>

<style lang="scss" scoped>
.historyBox {
  .headBox {
  display: flex;
  align-items: center;
  padding: 24px 0;
  box-shadow: inset 0px -1px 0px 0px #e5e5e5;
  .backBox {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    border-color: #f6f6f6;
    border-radius: 18px;
    padding: 7px 20px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
    }
    .backSvg {
      width: 16px;
      height: 16px;
    }
    .backFont {
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      line-height: 22px;
      margin-left: 4px;
    }
  }
  .titleFont {
    width: 716px;
    font-weight: 500;
    font-size: 20px;
    color: #2c2c2c;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
  .history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -0.5px 0px 0px #e5e5e5;
    padding: 20px 0;
    cursor: pointer;
    .rightBox {
      display: flex;
      align-items: center;
      .statusBox {
        padding: 3px 8px;
        background: #f6f6f6;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 16px;
        .status {
          font-weight: 400;
          font-size: 14px;
          color: #bdbdbe;
          line-height: 22px;
        }
        .red {
          width: 6px;
          height: 6px;
          background: #f53f3f;
          position: absolute;
          right: 0;
          top: -2px;
          border-radius: 50%;
        }
      }
      .statusBox2 {
        padding: 3px 8px;
        background: #7e72f6;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 16px;
        .status {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 22px;
        }
        .red {
          width: 6px;
          height: 6px;
          background: #f53f3f;
          position: absolute;
          right: 0;
          top: -2px;
          border-radius: 50%;
        }
      }
      .statusBox3 {
        padding: 3px 8px;
        background: #f4f3ff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 16px;
        .status {
          font-weight: 400;
          font-size: 14px;
          color: #7e72f6;
          line-height: 22px;
        }
        .red {
          width: 6px;
          height: 6px;
          background: #f53f3f;
          position: absolute;
          right: 0;
          top: -2px;
          border-radius: 50%;
        }
      }
      .title {
        font-weight: 400;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
        width: 668px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .time {
      font-weight: 400;
      font-size: 14px;
      color: #8a8b92;
      line-height: 22px;
    }
  }
  .empty {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 160px;
      .emptyImg {
        width: 100px;
        height: 100px;
      }
      .emptyText {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #2C2C2C;
        line-height: 24px;
      }
    }
  .pagation {
    margin-left: auto;
    margin-right: 0;
    margin-top: 94px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
