//不要格式化
export default {
  home: {
    Login: '登 录', // 登录
    Sign: '注 册', // 注册
    Slogan: '分享每一刻 收获每一刻', //分享每一刻 收获每一刻
    ImmedSign: '立即注册', // 立即注册
    Enter: '进入CloudMaster', // 进入CloudMaster
    Signup: '注册FEEMOO账号', // 注册飞猫盘账号
    Simple: '超简单极速注册', // 超简单极速注册
    Master: '开通CloudMaster', // 开通飞猫达人
    Exclusive: '达人专属标识/权益', //达人专属标识/权益
    ShareFile: '分享文件赚收益', // 分享文件赚收益
    Others: '他人下载/转存即获得收益', // 他人下载/转存即获得收益
    Advantages: '平台优势', // 平台优势
    Multiple: '多种模式灵活玩法多样', //多种模式灵活玩法多样
    CardTitle1: 'FEEMOO返利', // FEEMOO返利
    CardText1: 'FEEMOO是分享型网盘，成为达人后上传文件，生成分享链接供他人下载。', // 飞猫盘是分享型网盘，成为达人后上传文件，生成分享链接供他人下载。
    CardText1_2prefix: '可选择', //可选择
    CardText1_2Blod: '下载量返利模式', //下载量返利模式
    CardText1_2suffix: '，区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。', //，区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    CardText1_3prefix: '或选择', //或选择
    CardText1_3Blod: '会员返利模式', //会员返利模式
    CardText1_3suffix: '，不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁。', //，不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁。
    CardTitle2: '优惠码返利', // 优惠码返利
    CardText2: '使用专属您的邀请码邀请用户开通FEEMOO会员，您可获得返利收益。', // 使用专属您的邀请码邀请用户开通飞猫盘会员，您可获得返利收益
    CardTitle3: '下线返利', // 下线返利
    CardText3: '好友通过邀请链接注册FEEMOO账号后申请成为CloudMaster，您可获得好友收益的分成返利。', // 好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的分成返利。
    Income: '真实收益', // 真实收益
    Random: '随机展示上周部分收益提现记录 真实提现看得到', // 随机展示上周部分收益提现记录 真实提现看得到
    Feedback: '真实用户反馈', // 真实用户反馈
    Realization: '上线十年流量变现平台，真实反馈', // 上线十年流量变现平台，真实反馈
    Enjoy: '享受分享的乐趣，轻松赚取属于你的变现收益', // 享受分享的乐趣，轻松赚取属于你的变现收益。
  },
  nav: { //头部模块
    Login: '登 录', //登录
    Sign: '注 册', //注册
    Account: '个人账户', //个人账户
    SignOut: '退出', //退出
  },
  GradeColor: { //等级颜色
    Bronze:'青铜',//青铜
    Silver:'白银',//白银
    Gold:'黄金',//黄金
    Diamond:'钻石',//钻石
    Master:'大师',//大师
    King:'王者',//王者
  },
  IntrDaren: { //未登录展示
    FeeMooMaster: '飞猫达人', //飞猫达人
    TextOne: '开通成为飞猫达人后即可解锁多项推广返利功能！', //开通成为飞猫达人后即可解锁多项推广返利功能！
    OpenVIP: '立即开通', //立即开通
    PlatformPlay: '平台玩法', //平台玩法
    PlateRebate: '飞猫盘返利', //飞猫盘返利
    TextFive: '分享文件给其他用户下载/转存获得收益', //分享文件给其他用户下载/转存获得收益
    PromoCodeRebate: '优惠码返利', //优惠码返利
    TextSix: '用户通过优惠码开通会员获得返利收益', //用户通过优惠码开通会员获得返利收益
    DownlineRebate: '下线返利', //下线返利
    TextSeven: '邀请好友成为飞猫达人获得好友的收益分成', //邀请好友成为飞猫达人获得好友的收益分成
    ActivityRule: '活动规则', //活动规则
    TextTwo: '申请人需完善收款人信息', //申请人需完善收款人信息
    TextThree: '需遵守平台规则', //需遵守平台规则
    TextFour: '《飞猫盘用户服务协议》', //《飞猫盘用户服务协议》
  },
  FmCloud: { //飞猫盘模块
    DownloadRebateMode: '下载量返利模式', //下载量返利模式
    YouHave: '您有', //您有
    FilesUsingMode: '个文件使用该模式', //个文件使用该模式
    MyGrade: '我的等级：', //我的等级：
    TextOne:
      '区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益，', //区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益，
    DetailedIntroduction: '详细介绍', //详细介绍
    LargeFileIncome: '大文件收益', //大文件收益
    SmallFileIncome: '小文件收益', //小文件收益
    TextTwo: '今日大于100M文件下载量（次）', //今日大于100M文件下载量（次）
    TextThree: '今日小于100M文件下载量（次）', //今日小于100M文件下载量（次）
    WeekAward: '每周飞猫达人奖', //每周飞猫达人奖
    TextFour: '次日上午8点自动兑换到余额', //次日上午8点自动兑换到余额
    NotYetAwarded: '暂未获奖', //暂未获奖
    ReceiveReward: '领取奖励', //领取奖励
    Claimed: '已领取', //已领取
    MemberRebateModel: '会员返利模式', //会员返利模式
    TextFive: '每周三评奖', //每周三评奖
    TextSix:
      '不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁，', //不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁，
    RebateVIP: '返佣个数', //返佣个数
    CommissionRebate: '返佣金额', //返佣金额
    VIPRebatesToday: '今日返佣个数', //今日返佣个数
    CommissionBackToday: '今日返佣金额', //今日返佣个数
  },
  NavLeft: { //左侧菜单模块
    FEEMOO:'Feemoo',//飞猫盘
    PromotionCode:'优惠码',//优惠码
    DownlineRebate:'下线返利',//下线返利
    HelpCenter:'帮助中心',//帮助中心
    BalanceWithdrawal:'余额提现',//余额提现
    AccountManagement:'账号管理',//账号管理
  },
  MemberRebate: { //优惠码模块
    RebateCode:'返利优惠码',//返利优惠码
    IncomeData:'收益数据',//收益数据
    //返利优惠码模块
    rebateDisCode:{
      YourCurrentLevel:'您当前等级：',//您当前等级：
      Levels:'等级介绍',//等级介绍
      CouponCodeDiscount:'优惠码折扣',//优惠码折扣
      RebateRatio:'返利比例',//返利比例
      MyPromoCode:'我的优惠码：',//我的优惠码：
      MyCode:'我的优惠码',//我的优惠码
      Copy:'复制',//复制
      CopyCode: '复制优惠码', //复制优惠码
      MemberRebateIncome:'会员返利收益',//会员返利收益
    },
    //收益数据模块
    incomeData:{
      MemberRebateProgram:'会员返利计划',//会员返利计划
      TextOne:'用户通过优惠码开通会员，您可获得收益',//用户通过优惠码开通会员，您可获得收益
      Levels:'等级介绍',//等级介绍
      MyGrade: '我的等级：', //我的等级：
      MemberRebateIncome:'会员返利收益',//会员返利收益
      OpenMembership:'开通会员人数',//开通会员人数
      UserNickname:'用户昵称',//用户昵称
      OpeningTime:'开通时间',//开通时间
      RebateAmount:'返利金额',//返利金额
    },
  },
  OfflineRebate: { //下线返利模块
    InviteRegisterLink:'邀请注册链接',//邀请注册链接
    IncomeData:'收益数据',//收益数据
    yuan:'元',//元
    InvitationLink:{ //邀请注册链接
      Text:'玩法说明',//玩法说明
      TextOne:'好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的高额返利。',//好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的高额返利。
      TextTwo:'需通过你的邀请链接注册飞猫盘账号成功，并且申请开通成为飞猫达人。',//需通过你的邀请链接注册飞猫盘账号成功，并且申请开通成为飞猫达人
      TextThree:'好友成为达人后获得飞猫盘返利收益，你就能获得好友的收益分成。',//好友成为达人后获得飞猫盘返利收益，你就能获得好友的收益分成。
      TextFour:'邀请越多好友成为达人，获得更多收益分成，上不封顶。',//邀请越多好友成为达人，获得更多收益分成，上不封顶
      InviteNow:'立即邀请',//立即邀请
      CopyInvitationLink:'复制邀请链接',//复制邀请链接
      SavePicture:'保存图片',//保存图片
      DownLineIncome:'下线收益',//下线收益
      Save:'保存',//保存
      TodayEarnings:'今日收益',//今日收益
      Yesterday:'昨日',//昨日
      CurrentEarnings:'本月收益',//本月收益
      SeeMore:'查看更多',//查看更多
      InvitationRecord:'邀请记录',//邀请记录
      UserNickname:'用户昵称',//用户昵称
      TextFive:'是否开通CloudMaster',//是否开通飞猫达人
      InvitationTime:'邀请注册时间',//邀请注册时间
      TextSix:'获得99元下线返利',//获得99元下线返利
      HaveOpened:'已开通',//已开通
      HaveNotOpened:'未开通',//未开通
    },
    offIncomeData:{ //收益数据
      TodayEarnings:'今日收益',//今日收益
      YesterdayEarnings:'昨日收益',//昨日收益
      CurrentEarnings:'本月收益',//本月收益
      TextOne:'收益记录',//收益记录
      UserNickname:'用户昵称',//用户昵称
      TextTwo:'返利金额（元）',//返利金额（元）
      TextThree:'为什么我还没有获得下线返利收益：',//为什么我还没有获得下线返利收益：
      TextFour:'1、检查好友状态：请查看邀请记录，确认好友是否已成为飞猫达人。',//1、检查好友状态：请查看邀请记录，确认好友是否已成为飞猫达人。
      TextFive:'2、等待收益结算：若好友已是飞猫达人，需等待他们产生收益并在次日结算后，您才能获得返利收益分成。',//2、等待收益结算：若好友已是飞猫达人，需等待他们产生收益并在次日结算后，您才能获得返利收益分成。
    },
  },
  help: { //帮助中心模块
    FEEMOO:'Feemoo',//飞猫盘
    CouponCode:'优惠码',//优惠码
    DownlineRebate:'下线返利',//下线返利
    ComplaintBox:'意见箱',//意见箱
    ContactCustomer:'联系客服',//联系客服
    fmp:{ //飞猫盘
      EarningsProfile:'收益简介',//收益简介
      TextOne:'分享文件给其他用户下载/转存你就能获得收益，每日所得收益次日凌晨自动结算。',//分享文件给其他用户下载/转存你就能获得收益，每日所得收益次日凌晨自动结算。
      TextTwo:'下载量返利模式：',//下载量返利模式：
      TextThree:'区分大小文件按',//区分大小文件按
      TextFour:'下载量返利',//下载量返利
      TextFive:'，区分等级万次下载最高',//，区分等级万次下载最高
      TextSix:'1888元',//1888元
      TextSeven:'，每周三、周四',//，每周三、周四
      TextEight:'双倍',//双倍
      TextNine:'收益。',//收益。
      TextTen:'会员返利模式：',//会员返利模式：
      TextEleven:'不按下载次数计费，只根据下载用户开通会员进行按',//不按下载次数计费，只根据下载用户开通会员进行按
      TextTwelve:'比例分成',//比例分成
      TextThirteen:'，文件',//，文件
      TextFourteen:'不过期',//不过期
      TextFifteen:'，页面更简洁。',//，页面更简洁，适合
      TextSixteen:'大文件',//大文件
      TextSeventeen:'分享。',//分享。
      IncomeModelComparison:'收益模式对比',//收益模式对比
      Equity:'权益',//权益
      DownloadsRebateModel:'下载量返利模式',//下载量返利模式
      VIPRebateModel:'会员返利模式',//会员返利模式
      MoreModes:'更多模式即将上线',//更多模式即将上线
      DownloadRebateRules:'下载量返利模式规则',//下载量返利模式规则
      RebateMechanism:'1、返利机制：',//1、返利机制：
      TextEighteen:'按当日达人等级与当日所产生的下载量，在次日凌晨自动结算返利收益金额。',//按当日达人等级与当日所产生的下载量，在次日凌晨自动结算返利收益金额。
      levelRules:'2、等级规则：',//2、等级规则：
      TextNineteen:'根据昨日所产生的下载量来确定该模式下的达人返利等级，例如昨日下载量达到302，则今日达人等级为黄金。',//根据昨日所产生的下载量来确定该模式下的达人返利等级，例如昨日下载量达到302，则今日达人等级为黄金。
      doubleRevenue:'3、双倍收益：',//3、双倍收益：
      TextTwenty:'每周三、周四可获得双倍的下载量返利收益。',//每周三、周四可获得双倍的下载量返利收益。
      MemberRebateModel:'会员返利模式规则',//会员返利模式规则
      RebateMechanismTwo:'1、返利机制：',//1、返利机制：
      twentyOne:'选择会员返利模式后，其他用户通过你分享的下载链接访问，在下载或转存文件后，将与你建立24小时的绑定关系。在绑定期内，若用户在网页端或APP端上开通会员，你将获得其会员费用的',
      twentyTwo:'作为返利。',//作为返利。
      multiplePurchaseRebate:'2、多次购买返利：',//2、多次购买返利：
      twentyThree:'在24小时绑定期内，用户若多次开通会员，每次开通会员金额的',//在24小时绑定期内，用户若多次开通会员，每次开通会员金额的
      twentyFour:'均将作为您的返利。',//均将作为您的返利。
      BindingRestrictions:'3、绑定关系限制：',//3、绑定关系限制：
      twentyFive:'一旦用户与您建立了绑定关系，他们在24小时内将无法与其他达人建立新的绑定关系。',//一旦用户与您建立了绑定关系，他们在24小时内将无法与其他达人建立新的绑定关系。
      discountCode:'4、优惠码返利限制：',//4、优惠码返利限制：
      twentySix:'当用户使用优惠码开通会员，您只能获得优惠码返利，会员返利收益将无法叠加。',//当用户使用优惠码开通会员，您只能获得优惠码返利，会员返利收益将无法叠加。
    },
    discountCode:{ //优惠码
      TextOne:'什么是优惠码？',//什么是优惠码？
      TextTwo:'好友通过您的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。',//好友通过您的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。
      MasterRating:'达人等级',//达人等级
    },
    rebate:{ //下线返利
      TextOne:'什么是下线返利？',//什么是下线返利？
      TextTwo:'用户A邀请用户B注册，则用户B成为用户A的下线：',//用户A邀请用户B注册，则用户B成为用户A的下线：
      TextThree:'平台按用户B在飞猫盘推广活动中获得收益的15%额外给用户A奖励，持续有效。',//平台按用户B在飞猫盘推广活动中获得收益的15%额外给用户A奖励，持续有效。
      MasterRating:'达人等级',//达人等级
    },
    contactCust:{ //联系客服
      HotIssue:'热门问题',//热门问题
      View:'查看',//查看
      AdditionalHelp:'其他帮助',//其他帮助
      SubmitATicket:'提交工单',//提交工单
      SubmitNow:'立即提交',//立即提交
      FeedbackHistory:'反馈历史',//反馈历史
      ViewHistory:'进入查看',//进入查看
      TextOne:'联系客服',//联系客服
      TextTwo:'立即在线沟通',//立即在线沟通
      TextThree:'没找到问题，点此提交问题',//没找到问题，点此提交问题
      LatestAnnouncements:'最新公告',//最新公告
      FAQ:'常见问题',//常见问题
      Back:'返回',//返回
      LoginRegister:'登录注册',//登录注册
      WithdrawEarnings:'收益提现',//收益提现
    },
  },
  IncomeReflect: { //余额提现模块
    BalanceWithdrawal:'余额提现',//余额提现
    WithdrawalRecord:'提现记录',//提现记录
    PayeeManagement:'收款人管理',//收款人管理
    tabZero:{ //余额提现
      withdrawableAmount:'可提现金额',//可提现金额
      yuan:'元',//元
      Payee:'收款人',//收款人
      NewPayee:'新增收款人',//新增收款人
      PleaseSelect:'请选择',//请选择
      CashWithdrawal:'提现金额',//提现金额
      TextOne:'请输入提现金额',//请输入提现金额
      TextTwo:'每笔提现最低30元',//每笔提现最低30元
      phoneNumber:'手机号',//手机号
      PleasePhone:'请输入手机号',//请输入手机号
      VerificationCode:'输入验证码',//输入验证码
      TextThree:'请输入验证码',//请输入验证码
      TextFour:'获取验证码',//获取验证码
      Sent:'已发送',//已发送
      WithdrawCashNow:'立即提现',//立即提现
      TextFive:'您尚未进行手机绑定，提现成功后将默认绑定此手机号',//您尚未进行手机绑定，提现成功后将默认绑定此手机号
    },
    tabOne:{ //提现记录
      OrderNumber:'订单号',//订单号
      Amount:'金额',//金额
      Status:'状态',//状态
      Time:'时间',//时间
      TextOne:'提现记录为空~',//提现记录为空~
      awaitingProcessing:'待处理',//待处理
      Successful:'成功',//成功
      Fail :'失败',//失败
      ViewCauses :'查看原因',//查看原因
    },
    tabTwo:{ //收款人管理
      NewPayee:'新增收款人',//新增收款人
      UserNickname:'用户昵称',//用户昵称
      AlipayAccount:'支付宝账号',//支付宝账号
      IdNumber:'身份证号',//身份证号
      TextOne:'当月提现额度',//当月提现额度
      Controls:'操作',//操作
      Editor:'编辑',//编辑
      Delete:'删除',//删除
      NoRecord:'暂无记录',//暂无记录
    }
  },
  Account: { //账号管理模块
    TextOne:'咨询客服了解更多',//咨询客服了解更多
    ContactCellPhone:'联系手机',//联系手机
    ContactEmail:'联系邮箱',//联系邮箱
    FEEMOOCertification:'飞猫达人认证',//飞猫达人认证
    FEEMOOCertificationTwo:'CloudMaster',//CloudMaster
    SubscriptionNumberOpening:'订阅号开通',//订阅号开通
    CertifiedFEEMOOMaster:'已认证飞猫达人',//已认证飞猫达人
    CertifiedFEEMOOMasterTwo:'未认证CloudMaster',//未认证CloudMaster
    TextTwo:'已开通订阅号',//已开通订阅号
    TextThree:'修改绑定手机',//修改绑定手机
    TextFour:'修改绑定邮箱',//修改绑定邮箱
    Accredited:'已认证',//已认证
    TextFive:'进入订阅号',//进入订阅号
    BoundPhone:'绑定手机',//绑定手机
    BoundMailbox:'绑定邮箱',//绑定邮箱
    UncertifiedFEEMOO:'未认证飞猫达人',//未认证飞猫达人
    GetCertified:'去认证',//去认证
    SubscriptionNotAvailable:'未开通订阅号',//未开通订阅号
    GoAndActivate:'去开通',//去开通
    NotActivated:'未开通',//未开通
    ActivateNow:'立即开通',//立即开通
    ActivatedNEW:'已开通',//已开通
  },
  LevelTable: { //等级表格
    ClassInterest:'等级权益',//等级权益
    DownloadsYesterday:'昨日下载量',//昨日下载量
    TextOne:'大文件返利（元/万次）',//大文件返利（元/万次）
    TextTwo:'小文件返利（元/万次）',//小文件返利（元/万次）
    CouponCodeDiscount:'优惠码折扣',//优惠码折扣
    PromoCodeRevenue:'优惠码收益',//优惠码收益
  },
  TablePublic: { //表格组件模块
    UserNickname:'用户昵称',//用户昵称
    OpeningTime:'开通时间',//开通时间
    RebateAmount:'返利金额',//返利金额
    NoRecord:'暂无记录',//暂无记录
    QRCode:'二维码',//二维码
    InvitationCode:'邀请码',//邀请码
    InvitationLink:'邀请链接',//邀请链接
  },
  MemRbCom: { //会员返利收益组件
    Today:'今日',//今日
    Yesterday:'昨日',//昨日
    CurrentEarnings:'本月收益',//本月收益
    yuan:'元',//元
  },
  userNew: { // 信息悬浮框模块
    All: '全部', // 全部
    SystemNotification: '系统通知', // 系统通知
    OfficialNews: '官方消息', // 官方消息
    ReadAll: '全部已读', // 全部已读
    Nonews: '暂无消息', // 暂无消息
  },
}
