<template>
  <div>
    <div class="headBox">
      <div class="backBox" @click="back">
        <svg-btn class="backSvg" icon-class="icon-back-left" />
        <span class="backFont">{{ $t('help.contactCust.Back') }}</span>
      </div>
      <div class="titleFont">{{ title }}</div>
    </div>
    <div v-loading="loading">
      <div class="html" v-html="answer"></div>
      <div
        :class="{
          other: true,
          noLeft: !before.id || !after.id,
        }"
      >
        <div class="otherLeft" v-show="before.id" @click="goAskdetail(before.id)">
          <div class="svgBox">
            <svg-btn class="leftJ" icon-class="icon-jiantou-left" />
          </div>
          <span class="leftFont">{{ before.title }}</span>
        </div>
        <div class="otherRight" v-show="after.id" @click="goAskdetail(after.id)">
          <span class="rightFont">{{ after.title }}</span>
          <div class="svgBox">
            <svg-btn class="leftJ" icon-class="icon-jiantou-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="btBox">
      <div class="notAsk" @click="toLink">
        <span class="notFont">{{ $t('help.contactCust.TextThree') }} ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAskDetail } from '@/utils/apiList/user'
export default {
  data() {
    return {
      id: null,
      title: '',
      answer: '',
      before: {},
      after: {},
      loading:true,
    }
  },
  props: {
    detailId: {
      type: Number || String,
      default: '',
    },
  },
  mounted() {
    this.id = this.detailId
    if (this.id) {
      this.getAsk()
    }
  },
  methods: {
    getAsk() {
      getAskDetail({ id: this.id })
        .then(res => {
          if (res.status == '1') {
            this.title = res.data.title
            this.answer = res.data.answer
            this.before = res.data.before
            this.after = res.data.after
            this.loading = false
          } else {
            this.$toast(res.msg)
          }
        })
        .catch(error => {})
    },
    goAskdetail(id) {
      this.id = id
      this.getAsk()
    },
    back() {
      this.$emit('customEvent')
    },
    toLink(){
      window.open(`/submitWork`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.headBox {
  display: flex;
  align-items: center;
  padding: 24px 0;
  box-shadow: inset 0px -1px 0px 0px #e5e5e5;
  .backBox {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    border-color: #f6f6f6;
    border-radius: 18px;
    padding: 7px 20px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
    }
    .backSvg {
      width: 16px;
      height: 16px;
    }
    .backFont {
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      line-height: 22px;
      margin-left: 4px;
    }
  }
  .titleFont {
    width: 716px;
    font-weight: 500;
    font-size: 20px;
    color: #2c2c2c;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.html {
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 32px;
  color: #2c2c2c;
  line-height: 24px;
  word-wrap: break-word;
  word-break: break-all;
  img {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}
.other {
  display: flex;
  cursor: pointer;
  color: #8a8b92;
  justify-content: space-between;
  margin-bottom: 32px;
  .otherLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    padding: 14px 16px 14px 20px;
    color: #8a8b92;
    &:hover {
      .leftFont {
        color: #2c2c2c !important;
      }
    }
    .svgBox {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      &:hover {
        background-color: #f6f6f6;
        color: #2c2c2c !important;
      }
      .leftJ {
        width: 18px;
        height: 18px;
      }
    }
    .leftFont {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 371px;
      margin-left: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .otherRight {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    padding: 14px 20px 14px 15px;
    color: #8a8b92;
    &:hover {
      .rightFont {
        color: #2c2c2c !important;
      }
    }
    .svgBox {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      &:hover {
        background-color: #f6f6f6;
        color: #2c2c2c !important;
      }
      .leftJ {
        width: 18px;
        height: 18px;
      }
    }
    .rightFont {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 371px;
      margin-right: 13px;
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
.noLeft {
  justify-content: center;
}
.btBox {
  display: flex;
  justify-content: center;
  align-items: center;
  .notAsk {
    padding: 8px 24px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 28px;
    &:hover {
      background: #f6f6f6;
    }
    .notFont {
      font-weight: 500;
      font-size: 16px;
      color: #7e72f6;
      line-height: 24px;
    }
  }
}
</style>
